import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logos/logo-image-only.svg';
import './Footer.css';
import { useTranslation } from 'react-i18next';
export function Footer() {
  const { t } = useTranslation('common');
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-inner">
          <p className="copyright p-sm fw-medium mb-0">
            <Link to={'/'} className="me-2">
              <Image
                src={Logo}
                width={32}
                height={32}
                alt="Peasy Forms"
                fluid
              />
            </Link>
            <span style={{ color: "#351771" }}>
              {t("peasyformsisa@copyright2023processtalks")}
            </span>
          </p>
          <ul className="footer-nav list-unstyled">
            <li className="nav-item">
              <a href="mailTo:hello@peasyforms.com" className="link">
                {t('sendusanemail')}
              </a>
            </li>
            <li className="nav-item">
              <Link to="/termsofservice" className="link">
                {t('termsofservice')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/privacy" className="link">
                {t('privacypolicy')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
