import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Image, Stack } from "react-bootstrap";
import GoogleLogo from "../../assets/Google__G__Logo.svg.webp";
// import { ReactComponent as GoogleLogo} from "../../assets/Google__G__Logo.svg.webp";

import LoderGif from "../../assets/images/Lodder.gif";
import { PFButton } from "../PFButton";

const LinkWithGoogleModal = ({
  callApiTypeform,
  showLoader,
  generateGoogleForm,
  googleLinkButton,
  googleOauthlogin,
  setShowLinkWithPopup,
  authorize,
  isLodding,
}) => {
  const { t } = useTranslation("common");
  const handleClose = () => {
    setShowLinkWithPopup(false);
  };

  return (
    <>
      {localStorage.getItem("refreshToken") && (
        <div className="d-flex justify-content-center align-items-center mt-2 mb-5 ">
          {!showLoader ? (
            <div className="tooltip3 ">
              <PFButton
                className="buttonhover"
                style={{
                  padding: "12px 34px",
                  fontSize: "1.125rem",
                  fontWeight: "800",
                  borderRadius: 8,
                  border: "none",
                }}
                handleClick={generateGoogleForm}

              >
                {t("GenetareGooglefrom")}
              </PFButton>
              {/* asd */}

              <span className="tooltiptext">
                <p
                  style={{
                    textAlign: "justify",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  A form in Google will be generated
                  {/* : "A form in TypeForm will be generated"} */}
                </p>
              </span>
            </div>
          ) : (
            <PFButton
              width="100%"
              disabled={showLoader}
              style={{
                fontSize: "1.125rem",
                fontWeight: "800",
                borderRadius: 8,
                border: "none",
                backgroundColor: "#fbe54d",
                // borderColor: "#fbe54d",
                color: "#221047",
              }}
            >
              {t("GenetareGooglefrom")}
              <span style={{ marginLeft: "12px" }}>
                <img
                  alt="GitLodr"
                  width="25px"
                  height="25px"
                  color="red"
                  src={LoderGif}
                />
              </span>
            </PFButton>
          )}
        </div>
      )}
      {localStorage.getItem("TypeForm_accessToken") && (
        <div className="d-flex justify-content-center align-items-center mt-2 mb-5">
          {!isLodding ? (
            <div className="tooltip3">
              <PFButton
                className="buttonhover"
                style={{
                  padding: "12px 48px",
                  fontSize: "1.125rem",
                  fontWeight: "800",
                  borderRadius: 8,
                  border: "none",
                }}
                handleClick={()=>{callApiTypeform()
                  localStorage.setItem("PlateForm","Typeform")              
                }}
              >
                {t("GenetareTypefrom")}
              </PFButton>
              {/* asd */}

              <span className="tooltiptext">
                <p
                  style={{
                    textAlign: "justify",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  A form in Typeform will be generated
                  {/* : "A form in TypeForm will be generated"} */}
                </p>
              </span>
            </div>
          ) : (
            <PFButton
              disabled={isLodding}
              style={{
                padding: "12px 48px",
                fontSize: "1.125rem",
                fontWeight: "800",
                borderRadius: 8,
                border: "none",
                backgroundColor: "#fbe54d",
                // borderColor: "#fbe54d",
                color: "#221047",
              }}
            >
              {t("GenetareTypefrom")}
              <span style={{ marginLeft: "12px" }}>
                <img
                  alt="GitLodr"
                  width="25px"
                  height="25px"
                  color="red"
                  src={LoderGif}
                />
              </span>
            </PFButton>
          )}
        </div>
      )}
      {!localStorage.getItem("refreshToken") &&
        localStorage.getItem("TypeForm_accessToken") && (
          <>
            <div className="mb-3">
              <h1
                className="p dark  text-center fw-bold mt-2"
                style={{ fontSize: "25px", lineHeight: "35px" }}
              >
                {t("connectpeasyformstoyourgoogleformsspace")}
              </h1>
              <h6
                className="p dark mt-4"
                style={{ margin: "5px", padding: "10px 7px 10px 7px" }}
              >
                {t("thisactionwillallowtostoretheformsinyourgoogleformsspace")}
              </h6>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                onClick={() => {
                  googleOauthlogin();
                }}
                style={{
                  padding: "10px 25px 10px 25px",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid lightgray",
                  alignItems: "center",
                }}
              >
                <Image
                  src={GoogleLogo}
                  width={15}
                  height="auto"
                  style={{ cursor: "pointer" }}
                />
                <span style={{ marginLeft: 12 }}>Sign in with Google</span>
              </Button>
            </div>
          </>
        )}

      {!localStorage.getItem("TypeForm_accessToken") &&
        localStorage.getItem("refreshToken") && (
          <>
            <div className="mb-3 mt-4">
              <h1
                className="p dark  text-center fw-bold mt-2"
                style={{ fontSize: "25px", lineHeight: "35px" }}
              >
                {t("connectpeasyformstoyourTypeformspace")}
              </h1>
              <h6
                className="p dark mt-4"
                style={{ margin: "5px", padding: "10px 7px 10px 7px" }}
              >
                {t("thisactionwillallowtostoretheformsinyourTypeformspace")}
              </h6>
            </div>
            <div className="d-flex justify-content-center align-items-center pointer">
              <Button
                onClick={() => {
                  // googleOauthlogin();
                  authorize();
                }}
                style={{
                  padding: "10px 22px 10px 22px",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid lightgray",
                  alignItems: "center",
                }}
              >
                <span> Sign in with Typeform</span>
              </Button>
            </div>
          </>
        )}
      {!localStorage.getItem("TypeForm_accessToken") &&
        !localStorage.getItem("refreshToken") && (
          <>
            <div className="mb-4 mt-4">
              <h1
                className="p dark  text-center fw-bold mt-2"
                style={{ fontSize: "25px", lineHeight: "35px" }}
              >
                {t("connectpeasyformstoyourgoogleformsspace1")}
              </h1>
              {/* <h6
                className="p dark mt-4"
                style={{ margin: "5px", padding: "10px 7px 10px 7px" }}
              >
                {t("thisactionwillallowtostoretheformsinyourTypeformspace")}
              </h6> */}
            </div>
            <div className="d-flex justify-content-center align-items-center mb-4">
              <Button
                onClick={() => {
                  googleOauthlogin();
                }}
                style={{
                  padding: "10px 22px 10px 22px",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid lightgray",
                  alignItems: "center",
                }}
              >
                <Image
                  src={GoogleLogo}
                  width={15}
                  height="auto"
                  style={{ cursor: "pointer" }}
                />
                <span style={{ marginLeft: 12 }}>Sign in with Google</span>
              </Button>
            </div>
            <div className="d-flex justify-content-center align-items-center pointer">
              <Button
                onClick={() => {
                  // googleOauthlogin();
                  authorize();
                }}
                style={{
                  padding: "10px 25px 10px 25px",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid lightgray",
                  alignItems: "center",
                }}
              >
                <span> Sign in with Typeform</span>
              </Button>
            </div>
          </>
        )}
    </>
  );
};

export default LinkWithGoogleModal;
