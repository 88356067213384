import React, { useEffect, useState, useRef } from 'react';
import { db } from '../../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useGoogleLogin } from '@react-oauth/google';
import { client_id, client_secret } from '../../API/GoogleOauthApi';
import { Container } from 'react-bootstrap';

export function TermsofService() {
  return (
    <>
      <Container>
        <div style={{ color: '#9e9e9e' }}>
          <h1
            style={{
              textAlign: 'center',
              color: 'black',
              paddingTop: '4rem',
            }}
          >
            TERMS OF USE AND LEGAL NOTICE
          </h1>
          <p style={{ textAlign: 'justify' }}>
            In accordance with the obligations set forth in Law 34/2002, of July
            11, of Information Society Services and Electronic Commerce, the
            following information related to the website is provided below:
          </p>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              1. IDENTIFICATION DATA:
            </span>
            The legal holder of this website is the company Process Talks SL
            with address at C / Del Torn, 17174 Sant Feliu de Pallerols with
            N.I.F.: B02971182, telephone: +34 934 137 853 and email:
            hello@processtalks.com
          </p>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              2. ACCESS AND NAVIGATION:
            </span>
            Access and / or use of this website attributes the condition of the
            user and implies the acceptance of these terms and conditions of
            use.
          </p>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              3. USE OF THE WEBSITE:
            </span>{' '}
            Publications, information, data, files, and images posted on
            https://www.peasyforms.com (hereinafter, “the contents”) whose
            ownership is not specifically identified otherwise are property of
            Process Talks SL. The user is responsible of using the website and
            its contents in accordance with the provisions of these terms and
            conditions.
          </p>
          <p style={{ textAlign: 'justify' }}>
            The user undertakes to make appropriate use of the contents posted
            in this website and – without limitation– commits not to use them
            to:
          </p>
          <p style={{ textAlign: 'justify' }}>
            (i)engage in illicit activities, illegal or contrary to good faith
            and public order;(ii) disseminate content or propaganda of a racist,
            xenophobic, pornographic, apology of terrorism or violation of human
            rights; (iii) cause damage to Process Talks SL systems, its
            suppliers or third parties, introduce or spread computer viruses or
            any other physical or computer systems that are likely to cause the
            aforementioned damage; (iv) cause reputational damage to Process
            Talks SL; (iv) attempt to access and use the email accounts of other
            users and/or modify or manipulate their messages.
          </p>
          <p style={{ textAlign: 'justify' }}>
            Process Talks will not be responsible for any ideal, opinion, belief
            or thinking expressed by users through any participation tool that
            may be created, in accordance with the provisions of the applicable
            regulations.
          </p>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              {' '}
              1. THIRD PARTY WEBSITES:
            </span>{' '}
            Our website may include hyperlinks to other sites that are not
            operated or controlled by Process Talks SL. Therefore, Process Talks
            SL does not guarantee, nor is it responsible for the legality,
            reliability, usefulness, veracity and timeliness of the contents of
            such websites or their privacy practices. Please, before providing
            your personal information to these third–party websites keep in mind
            that their privacy practices may differ from ours.
          </p>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              {' '}
              2. PROCESSING OF PERSONAL DATA:
            </span>{' '}
            You can check all the information related to the processing of
            personal data in our Privacy Policy.
          </p>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              {' '}
              3. COOKIES:
            </span>{' '}
            You can check all the information related to the existence and
            management of cookies in our Cookies Policy.
          </p>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              {' '}
              4. MINORS:
            </span>
            The content of the website is suitable for most audiences although
            we inform you that users must be of legal age to choose one of the
            plans or services offered by the company. The legal holder of the
            website declines any responsibility arising from breaching this
            requirement.
          </p>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              {' '}
              5. INTELLECTUAL PROPERTY:
            </span>
            Unless otherwise specified, Process Talks SL is the owner of all
            intellectual property rights of its website, as well as all content
            contained therein (for example, images, audio, video, software,
            texts; trademarks or logos, combinations of colors, structure and
            design, etc.). Process Talks SL keeps all rights reserved. Any use
            that has not been expressly authorized by Process Talks SL will be
            considered a serious violation of its intellectual or industrial
            property rights. Reproduction, distribution and public communication
            of all or part of the contents of this website, for commercial
            purposes, in any media and by any technical means, without the
            authorization of Process Talks SL are expressly prohibited. The user
            undertakes and commits to respect the Intellectual and Industrial
            Property rights owned by Process Talks SL. The user must refrain
            from deleting, altering, evading or manipulating any protection
            device or security system of the website.
          </p>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              {' '}
              6. DISCLAIMER:
            </span>{' '}
            Process Talks SL is not responsible, in any case, for damages of any
            kind that may arise from the use or access to its website and
            specifically –but without limitation–: for errors or omissions in
            the contents, related to website’s availability as well as for the
            transmission of viruses or malicious or harmful programs through the
            contents, despite having adopted all the necessary technological
            measures to prevent this situation.
          </p>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              {' '}
              7. MODIFICATIONS:{' '}
            </span>{' '}
            Process Talks SL has the right to carry out –without prior notice
            –any modifications on its website, being able to change, delete or
            add all kind of contents and services.
          </p>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              {' '}
              8. LINKING POLICY:
            </span>{' '}
            a. Any hyperlink or interaction with a third–party website must be
            subject to the following conditions:
          </p>
          <p style={{ textAlign: 'justify' }}>
            ■ Total or partial reproduction of any of the services or contents
            of the website is not allowed without the prior and express
            authorization of Process Talks SL.
          </p>

          <p style={{ textAlign: 'justify' }}>
            ■ No deep–links or IMG or image links, or frames with the
            https://www.peasyforms.com/ website will be established without the
            prior and express authorization of its owner.
          </p>
          <p style={{ textAlign: 'justify' }}>
            ■ No false, inaccurate or incorrect statement will be made about the
            https://www.peasyforms.com/ website or about the services or
            contents thereof. Except for those signs that are part an hyperlink,
            a third–party we page will not contain any trademark, trade name,
            denomination, logo, slogan or other distinctive signs belonging to
            Process Talks SL, unless expressly authorized.
          </p>

          <p style={{ textAlign: 'justify' }}>
            ■ The existence of a hyperlink will not imply the existence of any
            commercial relationship between Process Talks SL and the legal
            holder of the third– party website, nor the knowledge and acceptance
            of Process Talks SL of the services and contents offered on said
            site.
          </p>

          <p style={{ textAlign: 'justify' }}>
            ■ Process Talks SL will not be responsible for the contents or
            services made available to the public on the website where there’s a
            hyperlink to Process Talks SL site, nor will be liable for any
            information and statement included therein.
          </p>
          <p style={{ textAlign: 'justify' }}>
            b. The website may make available to the user connections and links
            to other websites managed and controlled by third parties. These
            links have the sole function of facilitating users’ search for
            information, content and services on the Internet, without in any
            case being considered a suggestion, recommendation or invitation to
            visit them. Process Talks SL does not commercialize, direct, control
            or own the contents, services, information and statements available
            on these websites. Process Talks SL does not assume any type of
            responsibility, even indirectly or subsidiarily, for damages of any
            kind that may arise from access, maintenance, use, quality,
            legality, reliability and usefulness of the contents, information,
            communications, opinions, statements, products and services existing
            or offered on websites not managed by Process Talks SL and that are
            accessible through its website.
          </p>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              9. RIGHT OF EXCLUSION:
            </span>{' '}
            Process Talks SL reserves the right to deny or withdraw access to
            the site and / or the contracted Services without any prior notice
            to those users who fail to comply with these terms of use and legal
            notice.
          </p>

          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              {' '}
              10. DEFENSE OF RIGHTS:
            </span>{' '}
            Process Talks SL will pursue the breach of these conditions as well
            as any illegal use of its website by exercising all civil and
            criminal actions that may correspond by law.
          </p>

          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              {' '}
              11. MODIFICATION OF TERMS:
            </span>{' '}
            Process Talks SL can unilaterally modify at any time the terms and
            conditions of use set forth herein.
          </p>

          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              {' '}
              12. DURATION:
            </span>
            These terms and conditions will be in force until they are modified
            and the new ones are published.
          </p>

          <p style={{ textAlign: 'justify' }}>
            <span style={{ color: '#757575', fontWeight: '700' }}>
              {' '}
              13. APPLICABLE LEGISLATION:
            </span>
            The relationship between Process Talks SL and the user– in
            everything related to this website– will be governed by the
            provisions of the relevant Spanish legislation and regulations.
          </p>
        </div>
      </Container>
    </>
  );
}
