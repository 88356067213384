import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  redirect,
} from "react-router-dom";
import { Footer, Header } from "./components";
import { Home, ManageAccount } from "./pages";
import Scrolltotop from "./components/ScrollToTop/ScrollToTop";
import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { TermsofService } from "./pages/TermsofService/TermsofService";
import { PrivacyPolicy } from "./pages/PrivacyPolicy/PrivacyPolicy";
import { Toaster } from "react-hot-toast";
import CreateForm from "./pages/CreateForm/CreateForm";
function App() {
  const isLoggedIn = localStorage.getItem("USER") ? true : false;

  return (
    <div className="App">
      <Router>
        <GoogleOAuthProvider clientId="796758071306-vgnfjg3v13np23b6knjoirau10bkrfic.apps.googleusercontent.com">
          <Scrolltotop />
          <Header isLoggedIn={isLoggedIn} />
          <div className="page-content header-space">
            <Routes>
              {!isLoggedIn ? (
                <>
                  <Route path="/" element={<Home />} />
                  <Route path="/termsofservice" element={<TermsofService />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<CreateForm />} />
                  <Route path="/create-form" element={<CreateForm />} />
                  {/* <Route path='/manage-account' element={<ManageAccount />} /> */}
                  <Route path="/termsofservice" element={<TermsofService />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                </>
              )}
            </Routes>
          </div>
          <Footer />
        </GoogleOAuthProvider>
      </Router>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          success: {
            duration: 3000,
            theme: {
              primary: "#fff",
              secondary: "#fff",
            },
            style: {
              background: "#1ecc26",
              color: "#fff",
            },
          },
          error: {
            duration: 3000,
            theme: {
              primary: "red",
              secondary: "red",
            },
            style: {
              background: "#d60000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
}
export default App;
