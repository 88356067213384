import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./FormPreview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const InputType = ({ typeIcon, text, data, motivation, type }) => {
  const [options, setOptions] = useState(false);
  const { t } = useTranslation("common");

  const showOptions = () => {
    setOptions(!options);
  };
  return (
    <div className='inputype'>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            marginLeft: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ fontWeight: "bold" }}>{typeIcon}</div>
          <div style={{ marginLeft: "6px", marginTop: 4, fontSize: "12px" }}>
            {text}{" "}
          </div>
          {type && !data ? (
            <>
              <div
                style={{
                  marginLeft: "6px",
                  fontSize: "12px",
                  marginTop: 4,
                }}
              >
                . 2
              </div>
              <div
                className='optionHover'
                style={{
                  marginLeft: "6px",
                  fontSize: "12px",
                  cursor: "pointer",
                  marginTop: 4,
                }}
                onClick={showOptions}
              >
                options
              </div>
              <div>
                {options ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='29'
                    height='20'
                    fill='currentColor'
                    className='bi bi-arrow-up-short'
                    viewBox='0 0 16 16'
                  >
                    <path
                      fillRule='evenodd'
                      d='M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z'
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='29'
                    height='20'
                    fill='currentColor'
                    className='bi bi-arrow-down-short'
                    viewBox='0 0 16 16'
                  >
                    <path
                      fillRule='evenodd'
                      d='M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z'
                    />
                  </svg>
                )}
              </div>
            </>
          ) : (
            ""
          )}
          {data && (
            <>
              <div
                style={{
                  marginLeft: "6px",
                  fontSize: "12px",
                  marginTop: 4,
                }}
              >
                . {data?.length}
              </div>
              <div
                className='optionHover'
                style={{
                  marginLeft: "6px",
                  fontSize: "12px",
                  cursor: "pointer",
                  marginTop: 4,
                }}
                onClick={showOptions}
              >
                options
              </div>
              <div>
                {options ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='29'
                    height='20'
                    fill='currentColor'
                    className='bi bi-arrow-up-short'
                    viewBox='0 0 16 16'
                  >
                    <path
                      fillRule='evenodd'
                      d='M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z'
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='29'
                    height='20'
                    fill='currentColor'
                    className='bi bi-arrow-down-short'
                    viewBox='0 0 16 16'
                  >
                    <path
                      fillRule='evenodd'
                      d='M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z'
                    />
                  </svg>
                )}
              </div>
            </>
          )}
        </div>
        {motivation && (
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              alignItems: "center",
            }}
          >
            <div className='tooltip2'>
              <FontAwesomeIcon className='infoIcon' icon={faCircleInfo} />
              {/* asd */}

              <span className='tooltiptext'>
                <p
                  style={{
                    textAlign: "justify",
                    color: "white",
                    fontSize: "12px",
                    alignItems: "center",

                  }}
                >
                  {motivation}
                </p>
              </span>
            </div>

            <div style={{ marginLeft: "6px", fontSize: "12px" }}>
              {t("moreinfo")}
            </div>
          </div>
        )}
      </div>

      {options && (
        <div className='mt-3'>
          {data ? (
            data?.map((i) => (
              <li
                style={{
                  marginLeft: "35px",
                  fontSize: "12px",
                  marginBottom: 2,
                  fontWeight: 400,
                  color: "#4F4F4F",
                }}
                key={i}
              >
                {i}
              </li>
            ))
          ) : (
            <>
              <li
                style={{
                  marginLeft: "35px",
                  fontSize: "12px",
                  marginBottom: 2,
                  fontWeight: 400,
                  color: "#4F4F4F",
                }}
              >
                Yes
              </li>
              <li
                style={{
                  marginLeft: "35px",
                  fontSize: "12px",
                  marginBottom: 2,
                  fontWeight: 400,
                  color: "#4F4F4F",
                }}
              >
                No
              </li>
            </>
          )}
        </div>
      )}

      <hr className='hr' style={{ backgroundColor: "#E6E6E6" }} />
    </div>
  );
};

export default InputType;
