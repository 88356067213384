/* eslint-disable array-callback-return */
import { Col, Row, Spinner } from "react-bootstrap";
import React, { useMemo, useRef, useState } from "react";
import { SVGIcons } from "../SVGIcons.jsx";
import { client_secret } from "../../stripe/api.js";
import { useTranslation } from "react-i18next";
import { PFButton } from "../PFButton/PFButton.jsx";
import { toast } from "react-hot-toast";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase.js";
import countryList from "react-select-country-list";

const EnterPaymentDetailsModal = ({
  setIsActive,
  setCustomerId,
  setShowContinuePayment,
}) => {
  const { t } = useTranslation("common");
  const [cardData, setcardData] = useState();
  const [cardNo, setCardNo] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [cvc, setCvc] = useState("");
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const mmRef = useRef(null);
  const YYRef = useRef(null);
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const customerAddFirebase = async (cusiId, cardId, t) => {
    const user = JSON.parse(window.localStorage.getItem("USER"));

    try {
      const colRef = doc(
        db,
        `users/${user.uid}/FreeTrialYAMMY/formNumberCreate`
      );
      const docSnap = await getDoc(colRef);
      if (docSnap.exists()) {
        updateDoc(colRef, {
          customerId: cusiId,
          cardId: cardId,
        })
          .then(() => {
            setCustomerId(cusiId);
            toast.success(`${t("Successfully added payment details")}`);
            setIsLoading(false);
            setShowContinuePayment(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
            setShowContinuePayment(false);
          });
      } else {
        setDoc(colRef, {
          customerId: cusiId,
          cardId: cardId,
        })
          .then(() => {
            setCustomerId(cusiId);
            toast.success(`${t("Successfully added payment details")}`);
            setIsLoading(false);
            setShowContinuePayment(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
            setShowContinuePayment(false);
          });
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const createCard = (data) => {
    const user = JSON.parse(window.localStorage.getItem("USER"));
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${client_secret}`);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("card[number]", data.number);
    urlencoded.append("card[exp_month]", data.mm);
    urlencoded.append("card[exp_year]", data.yy);
    urlencoded.append("card[cvc]", data.cvv);
    urlencoded.append("card[name]", data?.name ?? user.displayName);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://api.stripe.com/v1/tokens", requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((result) => {
        if (result) {
          createCustomer(result.id, result.card.id, data, t);
        } else {
          setIsLoading(false);
          toast.error(`${t("something went wrong please try again")}`);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const createCustomer = (tokenid, cardid, data) => {
    const user = JSON.parse(window.localStorage.getItem("USER"));
    var urlencoded = new URLSearchParams();
    urlencoded.append("description", `${user.email}`);
    urlencoded.append("email", `${user.email}`);
    urlencoded.append("name", data?.name ?? `${user.displayName}`);
    urlencoded.append("address[city]", data?.city);
    urlencoded.append("address[country]", data?.country);
    urlencoded.append("address[line1]", data?.address);
    urlencoded.append("address[line2]", data?.Apt);
    urlencoded.append("address[postal_code]", data?.postalcode);
    urlencoded.append("address[state]", data?.region);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${client_secret}`);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://api.stripe.com/v1/customers", requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((result) => {
        if (result) {
          updateCustomer(result.id, tokenid, cardid, t);
        } else {
          toast.error(`${t("something went wrong please try again")}`);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const updateCustomer = (cusId, tokenId, cardid) => {
    // let Customerdata = new FormData();
    var urlencoded = new URLSearchParams();
    urlencoded.append("source", tokenId);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${client_secret}`);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(`https://api.stripe.com/v1/customers/${cusId}`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((result) => {
        if (result) {
          customerAddFirebase(result.id, cardid, t);
        } else {
          toast.error(`${t("something went wrong please try again")}`);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const paymentHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    createCard(cardData);
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value;
    setCardNo(value);

    const pattern = /^[0-9]{16}$/;
    if (!pattern.test(value)) {
      // setErrorMessage('Maximum 16 Number Required');
    } else {
      setErrorMessage("");
      mmRef.current.focus();
    }
  };

  const handleCvcNumber = (e) => {
    const value = e.target.value;
    setCvc(value);
    const pattern = /^[0-9]{3}$/;
    if (!pattern.test(value)) {
    } else {
      setErrorMessage("");
      inputRef1.current.focus();
    }
  };

  const handleMonthChange = (e) => {
    const value = e.target.value;
    setExpiryMonth(value);
    const pattern = /^[0-9]{2}$/;
    if (!pattern.test(value)) {
      // setErrorMessage('only 2 number');
    } else {
      setErrorMessage("");
      YYRef.current.focus();
    }
  };

  const handleYearChange = (e) => {
    const value = e.target.value;
    setExpiryYear(value);
    const pattern = /^[0-9]{4}$/;
    if (!pattern.test(value)) {
      // setErrorMessage('year Required 4 number');
    } else {
      setErrorMessage("");
      inputRef.current.focus();
    }
  };
  const options = useMemo(() => countryList().getData(), []);
  return (
    <>
      <h6 className='p fw-bold mb-4 text-black'>
        {t("enteryourpaymentdetails")}
      </h6>

      <form onSubmit={paymentHandler}>
        <Row>
          <Col xs={12}>
            <div className='fc-wrap cc-input'>
              <div className='cc-img'>{SVGIcons.CreditCard}</div>
              <input
                type='number'
                required
                maxLength='16'
                minLength='19'
                pattern='[0-9]{16,19}'
                placeholder={t("cardnumber1")}
                className='custom-fc'
                onBlur={(e) =>
                  setcardData({ ...cardData, number: e.target.value })
                }
                value={cardNo}
                onChange={(e) => handleCardNumberChange(e)}
                onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                }
                style={{ fontFamily: "sans-serif" }}
              />

              <div className='cc-extra'>
                <input
                  required
                  type='number'
                  placeholder='MM'
                  min={new Date().getMonth()}
                  max={new Date().getMonth() + 10}
                  value={expiryMonth}
                  className='expiry-fc'
                  onBlur={(e) =>
                    setcardData({ ...cardData, mm: e.target.value })
                  }
                  onChange={(e) => handleMonthChange(e)}
                  ref={mmRef}
                  onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }
                  style={{ fontFamily: "sans-serif" }}
                />
                <input
                  required
                  type='number'
                  placeholder='YYYY'
                  min={new Date().getFullYear()}
                  max={new Date().getFullYear() + 10}
                  value={expiryYear}
                  ref={YYRef}
                  className='expiry-fc'
                  onBlur={(e) =>
                    setcardData({ ...cardData, yy: e.target.value })
                  }
                  onChange={(e) => handleYearChange(e)}
                  onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }
                  style={{ fontFamily: "sans-serif" }}
                />
                <input
                  required
                  type='number'
                  placeholder='CVC'
                  className='cvc-fc'
                  maxLength={"3"}
                  onBlur={(e) =>
                    setcardData({ ...cardData, cvv: e.target.value })
                  }
                  value={cvc}
                  ref={inputRef}
                  onChange={(e) => {
                    handleCvcNumber(e);
                  }}
                  onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }
                  style={{ fontFamily: "sans-serif" }}
                />
              </div>
            </div>
            {errorMessage && (
              <p
                style={{
                  color: "red",
                  marginTop: "-5px",
                  fontSize: "10px",
                }}
              >
                {errorMessage}
              </p>
            )}
          </Col>
          <Col xs={12}>
            <div className='fc-wrap'>
              <input
                type='text'
                placeholder='Street address'
                className='custom-fc'
                onChange={(e) =>
                  setcardData({ ...cardData, address: e.target.value })
                }
                ref={inputRef1}
                style={{ fontFamily: "sans-serif" }}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className='fc-wrap'>
              <input
                type='text'
                placeholder={t("aptunitsuiteetc4")}
                className='custom-fc'
                onChange={(e) =>
                  setcardData({ ...cardData, Apt: e.target.value })
                }
                style={{ fontFamily: "sans-serif" }}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className='fc-wrap'>
              <select
                type='text'
                placeholder={t("aptunitsuiteetc")}
                className='custom-fc custom-select-fc'
                onChange={(e) =>
                  setcardData({ ...cardData, country: e.target.value })
                }
                style={{ fontFamily: "sans-serif" }}
              >
                <option value=''>{t("country")}</option>
                {options.map((option) => (
                  <option key={option.value} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col xs={12}>
            <div className='fc-wrap'>
              <input
                type='text'
                placeholder={t("citytownvillage5")}
                className='custom-fc'
                onChange={(e) =>
                  setcardData({ ...cardData, city: e.target.value })
                }
                style={{ fontFamily: "sans-serif" }}
              />
            </div>
          </Col>
          <Col sm={6}>
            <div className='fc-wrap'>
              <input
                type='text'
                placeholder={t("provinceregion6")}
                className='custom-fc'
                onChange={(e) =>
                  setcardData({ ...cardData, region: e.target.value })
                }
                style={{ fontFamily: "sans-serif" }}
              />
            </div>
          </Col>
          <Col sm={6}>
            <div className='fc-wrap'>
              <input
                required
                type='number'
                placeholder={t("postalcode1")}
                className='custom-fc'
                onChange={(e) =>
                  setcardData({ ...cardData, postalcode: e.target.value })
                }
                style={{ fontFamily: "sans-serif" }}
              />
            </div>
          </Col>
        </Row>
        {isLoading ? (
          <Spinner animation='border' />
        ) : (
          <PFButton
            variant={"dark"}
            size='small'
            // className={"fw-normal"}
            style={{ fontWeight: "100" }}
          >
            {t("save")}
          </PFButton>
        )}
      </form>
    </>
  );
};
export default EnterPaymentDetailsModal;
