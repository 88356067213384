import { PFRadio } from "../FormControls/PFRadio";
import { PFButton } from "../PFButton";
import PlanJuicy from "../../assets/images/icons/lemon-drops.svg";
import PlanDelicious from "../../assets/images/icons/lemon-half.svg";
import PlanTasty from "../../assets/images/icons//lemon-slice-round.svg";
import { Image, Spinner } from "react-bootstrap";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import React, { useEffect, useState } from "react";
import { PFModal } from "../PFModal";
import EnterPaymentDetailsModal from "./EnterPaymentDetailsModal";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
const ChangePlanModal = ({
  setPlane1,
  isOpen,
  setIsOpen,
  setShowChangePlanModal,
}) => {
  const { t } = useTranslation("common");

  const [plane, setPlane] = useState("");
  const [showContinuePayment, setShowContinuePayment] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [deliciousPlan, setDeliciousPlan] = useState("0,0€");
  const [title, setTitle] = useState(t("freeforms"));

  const [customerId, setCustomerId] = useState("");
  const [planeName, setPlaneName] = useState("YAMMY");

  const [yummySelect, setYummySelect] = useState(false);
  const [DELICIOUSSelect, setDELICIOUSSelect] = useState(false);
  const [JUICYSelect, setJUICYSelect] = useState(false);

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      const user = JSON.parse(window.localStorage.getItem("USER"));
      const colRef = doc(
        db,
        `users/${user?.uid}/FreeTrialYAMMY/formNumberCreate`
      );
      const docSnap = await getDoc(colRef);
      if (docSnap.exists()) {
        setPlane(docSnap.data().planType);
        setPlane1(docSnap.data().planType);
        setCustomerId(docSnap.data().customerId);
      }
      setShowLoader(false);
    })();
  }, []);

  const DELICIOUSForMonths = async () => {
    const user = JSON.parse(window.localStorage.getItem("USER"));
    const cuurentdate = new Date();
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const colRef = doc(db, `users/${user.uid}/FreeTrialYAMMY/formNumberCreate`);
    try {
      setShowLoader(true);
      const docSnap = await getDoc(colRef);
      if (docSnap.exists()) {
        await updateDoc(colRef, {
          Current_form: 0,
          status: "active",
          UserId: "",
          paidPlan: true,
          freeform: 0,
          startDate: cuurentdate.toLocaleTimeString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),

          endDate: date.toLocaleTimeString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
          totalForm: 25,
          planType: "delicious",
          isPayment: false,
          formField: [],
        })
          .then(() => {
            setShowLoader(false);
            setTimeout(() => {
              setShowChangePlanModal(false);
            }, 2000);
            setPlane("delicious");
            setPlane1("delicious");
            toast.success(`${t("DELICIOUSPlaneisactive")}`);
          })
          .catch((error) => {
            setShowLoader(false);
            console.log(error, error);
          });
      } else {
        setDoc(colRef, {
          Current_form: 0,
          status: "active",
          customerId: "",
          paidPlan: true,
          freeform: 25,
          startDate: cuurentdate.toLocaleTimeString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
          endDate: date.toLocaleTimeString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          totalForm: 0,
          planType: "juicy",
          isPayment: false,
          formField: [],
        })
          .then(() => {
            setPlane("delicious");
            setPlane1("delicious");
            setShowLoader(false);
            setTimeout(() => {
              setShowChangePlanModal(false);
            }, 2000);
            toast.success(`${t("DELICIOUSPlaneisactive")}`);
          })
          .catch((e) => {
            setShowLoader(false);
          });
      }
    } catch (e) {
      setShowLoader(false);
      console.log(e);
    }
  };
  const JUICYForMonths = async () => {
    const user = JSON.parse(window.localStorage.getItem("USER"));
    const cuurentdate = new Date();
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const colRef = doc(db, `users/${user.uid}/FreeTrialYAMMY/formNumberCreate`);
    try {
      setShowLoader(true);
      const docSnap = await getDoc(colRef);
      if (docSnap.exists()) {
        await updateDoc(colRef, {
          Current_form: 0,
          status: "active",
          UserId: "",
          paidPlan: true,
          freeform: 5,
          startDate: cuurentdate.toLocaleTimeString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),

          endDate: date.toLocaleTimeString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
          endTime: date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          totalForm: 25,
          planType: "juicy",
          isPayment: false,
          formField: [],
        })
          .then(() => {
            setShowLoader(false);
            setTimeout(() => {
              setShowChangePlanModal(false);
            }, 2000);
            setPlane("juicy");
            setPlane1("juicy");
            toast.success(`${t("TASTYplaneisactive")}`);
          })
          .catch((error) => {
            setShowLoader(false);
            console.log(error, error);
          });
      } else {
        setDoc(colRef, {
          Current_form: 0,
          status: "active",
          customerId: "",
          paidPlan: true,
          freeform: 25,
          startDate: cuurentdate.toLocaleTimeString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
          endDate: date.toLocaleTimeString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          totalForm: 0,
          planType: "juicy",
          isPayment: false,
          formField: [],
        })
          .then(() => {
            setPlane("juicy");
            setPlane1("juicy");
            setShowLoader(false);
            setTimeout(() => {
              setShowChangePlanModal(false);
            }, 2000);
            toast.success(`${t("TASTYplaneisactive")}`);
          })
          .catch((e) => {
            setShowLoader(false);
          });
      }
    } catch (e) {
      setShowLoader(false);
      console.log(e);
    }
  };

  const handleDelicious = () => {
    setDeliciousPlan("4,95€");
    setPlaneName("delicious");
    setTitle(t("25formspermonth"), t("needextraforms"));
    // setYummySelect(false);
    // setDELICIOUSSelect(true);
    // setJUICYSelect(false);
  };

  const handleJuicy = () => {
    setDeliciousPlan("0,50€");
    setPlaneName("juicy");
    setTitle(t("eachform"));
    // setYummySelect(false);
    // setDELICIOUSSelect(false);
    // setJUICYSelect(true);
  };

  useEffect(() => {
    if (plane === "delicious") {
      handleDelicious();
    } else if (plane === "juicy") {
      handleJuicy();
    } else if (plane === "YUMMY") {
      setDeliciousPlan("0,0€");
      setTitle(t("freeforms"));
      setPlaneName("YAMMY");
    }
  }, [plane]);

  return (
    <>
      {showLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <h6 className="p fw-bold mb-4 text-black">{t("upgradeplan1")}</h6>
          <div className="upgrade-plan-wrap">
            <div className="upgrade-plan">
              <div
                // style={{
                //   border: `${plane === "YAMMY" && "6px solid #fbe54d"}`,
                //   boxShadow: `${
                //     plane === "YAMMY" && "8px 8px 12px rgba(0, 0, 0, 0.25)"
                //   }`,
                // }}
                className={
                  planeName === "YAMMY" ? "plan-card active" : "plan-card"
                }
                onClick={() => {
                  // setYummySelect(true);
                  // setDELICIOUSSelect(false);
                  // setJUICYSelect(false);
                  setDeliciousPlan("0,0€");
                  setTitle(t("freeforms"));
                  setPlaneName("YAMMY");
                }}
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <Image src={PlanTasty} alt="" width={14} height={14} fluid />
                  <p className="mb-0 text-uppercase dark-blue">{t("YAMMY")}</p>
                </div>
                <div className="plan-desc">
                  <p className="p-sm dark-blue">{t("Tryusforfree")}</p>
                </div>

                <div className="plan-pricing">
                  <p className="mb-2 orange">
                    {t("Justforthefirst5.000!")}
                    {localStorage.getItem("language") !== "sp" && (
                      <span className="fw-bold"> {t("first")} 5.000!</span>
                    )}
                  </p>
                  <h2 className="dark-blue">{t("freeforms")}</h2>
                </div>

                <ul
                  className="plan-features ps-4 dark-blue "
                  style={{ fontSize: "14px", fontWeight: "400" }}
                >
                  <li>{t("trypeasyformsforfree1")}</li>
                  <li>{t("createpersonalizedformsandsurveys")}</li>
                  <li>{t("needextraformseach")}</li>
                  <li>
                    {t(
                      "itisforyouifyoudontexpecttocreatemanyformsandjustwanttotry1"
                    )}
                  </li>
                  <li> {t("activefor3yearsonly!1")}</li>
                </ul>

                <div className="text-center mt-auto">
                  {plane === "YAMMY" ? (
                    <PFButton variant={"secondary"} disabled>
                      {t("currentplan")}
                    </PFButton>
                  ) : (
                    <PFButton
                      // onClick={TESTYForMonths}
                      variant={"secondary"}
                      disabled
                    >
                      {t("usedit")}
                    </PFButton>
                  )}
                  {/* {plane === 'delicious' ? (
                    <PFButton variant={'secondary'} disabled>
                      {t('currentplan')}
                    </PFButton>
                  ) : (
                    <PFButton
                      disabled={plane === 'juicy' ? true : false}
                      variant={'secondary'}
                      onClick={DELICIOUSForMonths}
                    >
                      {t('chooseit1')}
                    </PFButton>
                  )} */}
                </div>
              </div>

              <div
                // style={{
                //   border: `${plane === "delicious" && "6px solid #fbe54d"}`,
                //   boxShadow: `${
                //     plane === "delicious" && "8px 8px 12px rgba(0, 0, 0, 0.25)"
                //   }`,
                // }}
                className={
                  planeName === "delicious" ? "plan-card active" : "plan-card"
                }
                onClick={() => {
                  // setDeliciousPlan("4,95€");
                  // setPlaneName("DELICIOUS");
                  // setTitle(t("25formspermonth"), t("needextraforms"));
                  // setYummySelect(false);
                  // setDELICIOUSSelect(true);
                  // setJUICYSelect(false);
                  handleDelicious();
                }}
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <Image
                    src={PlanDelicious}
                    alt=""
                    width={14}
                    height={14}
                    fluid
                  />
                  <p className="mb-0 text-uppercase dark-blue">
                    {t("deliclous")}
                  </p>
                </div>
                {/* <div className="plan-desc">
                  <p className="p-sm fw-bold mb-0 plan-recommended">
                    {t('recommended')}
                  </p>
                  <p className="p-sm">{t('generatemonthlyasypeasyforms1')}</p>
                </div> */}
                <div className="plan-desc">
                  <p className="p-sm fw-bold mb-0 plan-recommended">
                    {t("recommended")}
                  </p>
                  <p className="p-sm mt-2 dark-blue">
                    {t("generatemonthlyasypeasyforms1")}
                  </p>
                </div>
                <div className="plan-pricing">
                  <p className="mb-2 orange">
                    <strike className="h2 fw-bold me-2 orange">9.99 €</strike>
                    {t("Until30thofjune")}
                  </p>

                  <h2 className="dark-blue">4,95€/ {t("month")}</h2>
                </div>
                <ul className="plan-features ps-4 dark-blue">
                  {/* <li className="orange fw-bold">
                    {t('onlyuntil')} 30 {t('ofjune')}
                  </li> */}
                  {/* <li className="orange fw-bold">{t("onlyuntil30thofJune")}</li> */}

                  <li>{t("25formspermonth")}</li>
                  <li>{t("needextraform020€seach")}</li>
                  <li>
                    {t("monthlyplanforcompaniesandindependentprofessionaluse")}
                  </li>
                  <li>
                    {t(
                      "itisforyouifyouneedanamazingformcreatortohelpyousavetimeandmoney"
                    )}
                  </li>
                </ul>

                <div className="text-center mt-auto">
                  {plane === "delicious" ? (
                    <PFButton variant={"secondary"} disabled>
                      {t("currentplan")}
                    </PFButton>
                  ) : (
                    <PFButton
                      disabled={plane === "juicy" ? true : false}
                      variant={"secondary"}
                      // onClick={DELICIOUSForMonths}
                    >
                      {t("chooseit1")}
                    </PFButton>
                  )}
                </div>
              </div>

              <div
                // style={{
                //   border: `${plane === "juicy" && "6px solid  #fbe54d"}`,
                //   boxShadow: `${
                //     plane === "juicy" && "8px 8px 12px rgba(0, 0, 0, 0.25)"
                //   }`,
                // }}
                className={
                  planeName === "juicy" ? "plan-card active" : "plan-card"
                }
                onClick={() => {
                  // setDeliciousPlan("0,50€");
                  // setPlaneName("JUICE");
                  // setTitle(t("eachform"));
                  // setYummySelect(false);
                  // setDELICIOUSSelect(false);
                  // setJUICYSelect(true);
                  handleJuicy();
                }}
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <Image src={PlanJuicy} alt="" width={14} height={14} fluid />
                  <p className="mb-0 text-uppercase dark-blue">{t("juicy")}</p>
                </div>

                <div className="plan-desc mt-4">
                  <p className="p-sm dark-blue">{t("payyourformsasyougo")}</p>
                </div>
                <div className="plan-pricing dark-blue ">
                  <h2>0,50€/ {t("form")}</h2>
                </div>
                <ul className="plan-features ps-4 dark-blue">
                  <li>{t("createpersonalizedformsandsurveys")}</li>
                  <li>{t("easypeasypayasyougo")}</li>
                  <li>{t("itisforyouifyoujustneedanoccasionalformcreator")}</li>
                </ul>

                <div className="text-center mt-auto">
                  {plane === "juicy" ? (
                    <PFButton variant={"secondary"} disabled>
                      {t("currentplan")}
                    </PFButton>
                  ) : (
                    <PFButton
                      variant={"secondary"}
                      // onClick={JUICYForMonths}
                      disabled={plane === "delicious" ? true : false}
                    >
                      {t("chooseit1")}
                    </PFButton>
                  )}
                </div>
              </div>
            </div>
            <div className="plan-bill-wrap ">
              <div className="plan-bill mt-5">
                <div className="bill-divider">
                  <h6 className="p mb-0 fw-bold mb-0 text-black">
                    {t("upgradeyourplan1")}
                  </h6>
                </div>
                <div className="bill-divider bill-info">
                  <h6 className="p mb-2 fw-medium text-black">
                    {t("billingcycle1")}
                  </h6>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div className="bill-info-title">
                      {/* <PFRadio
                        id={"annual"}
                        label={t("annual")}
                        name={"bill-cycle"}
                      /> */}
                    </div>
                    <div className="bill-info-content d-flex justify-content-end">
                      <PFRadio
                        id={"monthly"}
                        label={t("monthly")}
                        name={"bill-cycle"}
                        checked={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="bill-divider bill-info">
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div className="bill-info-title fs-14">{title}</div>
                    <div className="bill-info-content">{deliciousPlan}</div>
                  </div>
                </div>
                <div className="bill-divider bill-info mb-5">
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div className="bill-info-title p fw-medium text-black">
                      {t("subtotal")}
                    </div>

                    <div className="bill-info-content h6 fw-bold mb-0 h5 text-black">
                      {deliciousPlan}
                    </div>
                  </div>
                </div>
              </div>

              {customerId ? (
                <PFButton
                  disabled={
                    plane === "juicy" || plane === "delicious" ? true : false
                  }
                  variant={"blue"}
                  className="w-100 mt-auto"
                  handleClick={() => {
                    if (planeName === "juicy") {
                      JUICYForMonths();
                    } else if (planeName === "delicious") {
                      DELICIOUSForMonths();
                    }
                  }}
                >
                  {/* <span>{t("addpaymentinformation")}</span> */}
                  <span>{t("activeplan")}</span>
                </PFButton>
              ) : (
                <PFButton
                  variant={"blue"}
                  className="w-100 mt-auto"
                  handleClick={() => {
                    setShowContinuePayment(true);
                  }}
                >
                  {/* <span>{t("addpaymentinformation")}</span> */}
                  <span>{t("addpaymentinformation")}</span>
                </PFButton>
              )}
            </div>
          </div>
          <PFModal
            isOpen={showContinuePayment}
            handleClose={() => setShowContinuePayment(false)}
          >
            <EnterPaymentDetailsModal
              setShowContinuePayment={setShowContinuePayment}
              setCustomerId={setCustomerId}
            />
          </PFModal>
        </>
      )}
    </>
  );
};
export default ChangePlanModal;
