import { Pagination } from "swiper";
import { PFButton } from "../../components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Container, Image, Nav, Row, Tab } from "react-bootstrap";
import PlanJuicy from "../../assets/images/icons/lemon-drops.svg";
import PlanDelicious from "../../assets/images/icons/lemon-half.svg";
import PlanTasty from "../../assets/images/icons/lemon-slice-round.svg";
import GoogleForms from "../../assets/images/icons/google-forms.png";
import HeroBanner from "../../assets/images/hero-banner.svg";
import Template2 from "../../assets/images/templates/order.png";
import Template1 from "../../assets/images/templates/application.svg";
import Template3 from "../../assets/images/templates/surveys.png";
import Template4 from "../../assets/images/templates/quiz.png";
import Template5 from "../../assets/images/templates/feedback.png";
import Template6 from "../../assets/images/templates/membership.png";
import Template7 from "../../assets/images/templates/customer.png";
import Template8 from "../../assets/images/templates/registration.png";
import "./Home.css";
import { ReactComponent as Emojicon } from "../../assets/images/icons/emoji.svg";
import { ReactComponent as Pencileicon } from "../../assets/images/icons/pencile.svg";
import { ReactComponent as Horsecon } from "../../assets/images/icons/horse.svg";
import typeFormImg from "../../assets/Typeform_logo-01.svg.png";
import { signInWithGoogle } from "../../firebase";
import { useTranslation } from "react-i18next";
import GoogleLogo from "../../assets/images/templates/btn_google_signin_light_normal_web@2x.png";

export function Home() {
  const { t } = useTranslation("common");

  const templateSliderSettings = {
    modules: [Pagination],
    spaceBetween: 10,
    slidesPerView: 1,
    pagination: {
      el: ".custom-pagination",
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };

  const TemplatesList = [
    {
      id: 1,
      name: `${t("applicationforms")}`,
      description: `${t("createaformforasalesrepresentativejobapplication")}`,
      color: "#becce1",
      image: Template1,
    },
    {
      id: 2,
      name: `${t("orderform")}`,
      description: `${t(
        "iwantaformfororderingmynewproducttheformshouldincludethenumberofitemstopurchase"
      )}`,
      color: "#fec3a7",
      image: Template2,
    },
    {
      id: 3,
      name: `${t("surveysandpolls")}`,
      description: `${t(
        "ineedaformtoobtaintheworkerpreferencesforthenewdesignoftheshop"
      )}`,
      color: "#e4ffaa",
      image: Template3,
    },
    {
      id: 4,
      name: `${t("quizandassessmentforms")}`,
      description: `${t(
        "aformtotesttheusersknowledgeonthelakersteaminseason2019"
      )}`,
      color: "#e6dbff",
      image: Template4,
    },
    {
      id: 5,
      name: `${t("feedbackandevaluationforms")}`,
      description: `${t("iwanttoknowmyemployeesopinionaboutourlastmeeting")}`,
      color: "#c9ede7",
      image: Template5,
    },
    {
      id: 6,
      name: `${t("membershipandsubscriptionforms")}`,
      description: `${t(
        "anewslettersubscriptionformforvisitorstosignuptoreceivethelatestnews"
      )}`,
      color: "#ffdbf7",
      image: Template6,
    },
    {
      id: 7,
      name: `${t("customersatisfactionforms")}`,
      description: `${t(
        "iwanttodiscoverifmycustomersaresatisfiedwithmyproductsquality"
      )}`,
      color: "#dafffd",
      image: Template7,
    },
    {
      id: 8,
      name: `${t("contactandregistrationforms")}`,
      description: `${t("ineedaformtoaskmyvisitorstosignuponmywebsite")}`,
      color: "#FFE7AA",
      image: Template8,
    },
  ];
  const PlanCardsforYears = () => {
    return (
      <div className="upgrade-plan-wrap" style={{ fontWeight: "500" }}>
        <div className="upgrade-plan">
          <div className="plan-card">
            <div className="d-flex align-items-center gap-2 mb-2">
              <Image src={PlanTasty} alt="" width={14} height={14} fluid />
              <p className="mb-0 text-uppercase">{t("YAMMY")}</p>
            </div>
            <div className="plan-desc">
              <p className="p">{t("tryusforfree")}</p>
            </div>
            <div className="plan-pricing">
              <p className="mb-2 orange">
                {t("justforthe")}{" "}
                <span className="fw-bold">
                  {t("first")} 5.000 {t("users")}!
                </span>
              </p>
              <h2 className="h1">{t("freeforms")}</h2>
            </div>
            <ul className="plan-features ps-4">
              <li className="orange fw-bold"> {t("activefor3yearsonly!")}</li>
              <li>
                {/* <span style={{ fontWeight: "bold" }}>{t("formsforfree")} </span> */}
                {t("trypeasyformsforfree")}
              </li>
              <li>{t("createpersonalizedformsandsurveys")}</li>
              <li>{t("needextraformseach")}</li>
              <li>
                {t(
                  "itisforyouifyoudontexpecttocreatemanyformsandjustwanttotry"
                )}
              </li>
            </ul>
            <div className="text-center mt-auto">
              <PFButton variant={"secondary"} onClick={signInWithGoogle}>
                {t("chooseit")}
              </PFButton>
            </div>
          </div>
          <div className="plan-card">
            <div className="d-flex align-items-center gap-2 mb-2">
              <Image src={PlanDelicious} alt="" width={14} height={14} fluid />
              <p className="mb-0 text-uppercase">{t("deliclous")}</p>
            </div>
            <div className="plan-desc">
              <p className="p-sm fw-bold mb-0 plan-recommended">
                {t("recommended")}
              </p>
              <p className="p mt-2">{t("generateyearlyeasypeasyforms")}</p>
            </div>
            <div className="plan-pricing">
              <p className="mb-2 orange">
                <strike className="h2 fw-bold me-2 orange">9,99 €</strike>
              </p>

              <h2 className="h1">
                49,99€/ <span className="fw-normal">{t("year")}</span>
              </h2>
            </div>
            <ul className="plan-features ps-4">
              <li className="orange fw-bold">{t("onlyuntil30thofJune")}</li>
              <li>25 {t("personalizedformsandsurveysperyear")}</li>
              <li>{t("needextraform020€seach")}</li>
              <li>
                {t("yearlyplanforcompaniesandindependentprofessionaluse")}
              </li>
              <li>
                {t(
                  "itisforyouifyouneedanamazingformcreatortohelpyousavetimeandmoney"
                )}
              </li>
            </ul>
            <div className="text-center mt-auto">
              <PFButton variant={"secondary"} onClick={signInWithGoogle}>
                {t("chooseit")}{" "}
              </PFButton>
            </div>
          </div>
          <div className="plan-card">
            <div className="d-flex align-items-center gap-2 mb-2">
              <Image src={PlanJuicy} alt="" width={14} height={14} fluid />
              <p className="mb-0 text-uppercase">{t("juicy")}</p>
            </div>
            <div className="plan-desc">
              <p className="p">{t("payyourformsasyougo")}</p>
            </div>
            <div className="plan-pricing">
              <h2 className="h1">
                0,50€/ <span className="fw-normal">{t("form")}</span>{" "}
              </h2>
            </div>
            <ul className="plan-features ps-4">
              <li>{t("createpersonalizedformsandsurveys")}</li>
              <li>{t("easypeasypayasyougo")}</li>
              <li>
                {t(
                  "itisforyouifyouarenotaproandjustneedanamazingformcreatoroccasionally"
                )}
              </li>
            </ul>
            <div className="text-center mt-auto">
              <PFButton variant={"secondary"} onClick={signInWithGoogle}>
                {t("chooseit")}
              </PFButton>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const PlansCardsforMonths = () => {
    return (
      <div className="upgrade-plan-wrap " style={{ fontWeight: "500" }}>
        <div className="upgrade-plan">
          <div className="plan-card">
            <div className="d-flex align-items-center gap-2 mb-2">
              <Image src={PlanTasty} alt="" width={14} height={14} fluid />
              <p className="mb-0 text-uppercase">{t("YAMMY")}</p>
            </div>
            <div className="plan-desc">
              <p className="p">{t("tryusforfree")}</p>
            </div>
            <div className="plan-pricing">
              <p className="mb-2 orange">
                {t("justforthe")}{" "}
                <span className="fw-bold">
                  {t("first")} 5.000 {t("users")}!
                </span>
              </p>
              <h2 className="h1">{t("freeforms")}</h2>
            </div>

            <ul className="plan-features ps-4">
              <li className="orange fw-bold">{t("activefor3monthsonly")}</li>

              <li>
                {/* <span style={{ fontWeight: "bold" }}>{t("formsforfree")} </span> */}
                {t("trypeasyformsforfree")}
              </li>
              <li>{t("createpersonalizedformsandsurveys")}</li>
              <li>{t("needextraformseach")}</li>
              <li>
                {t(
                  "itisforyouifyoudontexpecttocreatemanyformsandjustwanttotry"
                )}
              </li>
            </ul>

            <div className="text-center mt-auto">
              <PFButton variant={"secondary"} onClick={signInWithGoogle}>
                {t("chooseit")}
              </PFButton>
            </div>
          </div>

          <div className="plan-card">
            <div className="d-flex align-items-center gap-2 mb-2">
              <Image src={PlanDelicious} alt="" width={14} height={14} fluid />
              <p className="mb-0 text-uppercase">{t("deliclous")}</p>
            </div>
            <div className="plan-desc">
              <p className="p-sm fw-bold mb-0 plan-recommended">
                {t("recommended")}
              </p>
              <p className="p mt-2">{t("Generatemonthlyeasypeasyforms")}</p>
            </div>
            <div className="plan-pricing">
              <p className="mb-2 orange">
                <strike className="h2 fw-bold me-2 orange">9,99 €</strike>
              </p>

              <h2 className="h1">
                4,95€/ <span className="fw-normal">{t("month")}</span>
              </h2>
            </div>
            <ul className="plan-features ps-4">
              <li className="orange fw-bold">{t("onlyuntil30thofJune")}</li>
              <li>{t("personalizedformsandsurveyspermonth")}</li>
              <li>{t("needextraform020€seach")}</li>
              <li>
                {t("monthlyplanforcompaniesandindependentprofessionaluse")}
              </li>
              <li>
                {t(
                  "itisforyouifyouneedanamazingformcreatortohelpyousavetimeandmoney"
                )}
              </li>
            </ul>

            <div className="text-center mt-auto">
              <PFButton variant={"secondary"} onClick={signInWithGoogle}>
                {t("chooseit")}
              </PFButton>
            </div>
          </div>

          <div className="plan-card">
            <div className="d-flex align-items-center gap-2 mb-2">
              <Image src={PlanJuicy} alt="" width={14} height={14} fluid />
              <p className="mb-0 text-uppercase">{t("juicy")}</p>
            </div>
            <div className="plan-desc">
              <p className="p">{t("payyourformsasyougo")}</p>
            </div>
            <div className="plan-pricing">
              <h2 className="h1">
                0,50€/ <span className="fw-normal">{t("form")}</span>
              </h2>
            </div>
            <ul className="plan-features ps-4">
              <li>{t("createpersonalizedformsandsurveys")}</li>
              <li>{t("easypeasypayasyougo")}</li>
              <li>
                {t(
                  "itisforyouifyouarenotaproandjustneedanamazingformcreatoroccasionally"
                )}
              </li>
            </ul>

            <div className="text-center mt-auto">
              <PFButton variant={"secondary"} onClick={signInWithGoogle}>
                {t("chooseit")}
              </PFButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="home-page">
      <section className="hero">
        <Container>
          <Row>
            <Col
              lg={6}
              className="d-flex align-items-start justify-content-center flex-column"
            >
              <div className="py-3">
                <h1 className="h1">
                  {t("createyourforms")} <br />
                  {t("easypeasy")}
                </h1>
                <p className="h5 fw-normal mb-4">
                  {t("theworldseasiestformcreator")}
                </p>

                <PFButton variant={"secondary"} onClick={signInWithGoogle}>
                  <span>{t("start")}</span>
                </PFButton>
              </div>
            </Col>
            <Col lg={6} className="d-flex justify-content-center">
              <Image src={HeroBanner} width={500} fluid />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="how-it-works" id="howitworks">
        <Container>
          <h5
            className="fw-bold primary text-center mb-5"
            style={{ color: "#FBE54D" }}
          >
            <span>{t("aseasyascountingto3")}</span>
          </h5>

          <Row className="gy-4 gy-lg-0">
            <Col lg={4}>
              <div className="hiw-card">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="hiw-count flex-shrink-0">1</div>
                  <h2 className="flex-shrink-0 mb-0 hiw-step-name">
                    {t("write")}
                  </h2>
                </div>

                <p className="h5">
                  {t("describeinyouownwordswhatformdoyouneed")}
                </p>

                <div className="hiw-icon">
                  <Pencileicon widths="40px" height="40px" />
                </div>
              </div>
            </Col>

            <Col lg={4}>
              <div className="hiw-card">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="hiw-count flex-shrink-0">2</div>
                  <h2 className="flex-shrink-0 mb-0 hiw-step-name">
                    {t("create")}
                  </h2>
                </div>

                <p className="h5">
                  {t("reviewthefieldsandcreateyourforminlessthan10seconds")}
                </p>

                <div className="hiw-icon">
                  <Horsecon />
                </div>
              </div>
            </Col>

            <Col lg={4}>
              <div className="hiw-card">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="hiw-count flex-shrink-0">3</div>
                  <h2 className="flex-shrink-0 mb-0 hiw-step-name">
                    {t("voilà!")}
                  </h2>
                </div>

                <p className="h5">
                  {t("yourformwillbeautomaticallycreatedinyouraccount")}
                </p>

                <div className="hiw-icon text-nowrap ">
                  <Emojicon />
                  <Image
                    src={GoogleForms}
                    width={145}
                    alt="Google Forms"
                    className="ms-1"
                    fluid
                  />
                  <Image
                    src={typeFormImg}
                    width={135}
                    alt="Google Forms"
                    className=""
                    fluid
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="experts">
        <Container>
          <h5 className="fw-bold  text-center mb-5 lh-1">
            {t("notanexpert?checkourexamples")}
          </h5>
          <Swiper {...templateSliderSettings} className="template-slider">
            {TemplatesList.map((t) => (
              <SwiperSlide key={t.id}>
                <div className="template-card">
                  <div className="template-details">
                    <h6 className="fw-bold">{t.name}</h6>
                    <p className="mb-0 fw-normal">{t.description}</p>
                  </div>
                  <div
                    className="template-image"
                    style={{
                      backgroundColor: t.color,
                    }}
                  >
                    <Image
                      src={t.image}
                      alt=""
                      width={175}
                      height={117}
                      fluid
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="custom-pagination text-center mt-4"></div>
        </Container>
      </section>
      {/* <section className="plans" id="pricing">
        <Container>
          <h5
            className="fw-bold text-white text-center mb-3 mt-2 lh-1"
            style={{ fontSize: "20px" }}
          >
            {t("findyourprefectplan")}
          </h5>
          <Tab.Container
            id="planTabs"
            defaultActiveKey="monthlyPlans"
            className="plans-tabs"
          >
            <div className="mb-5 d-flex justify-content-center">
              <Nav variant="pills" className="plans-nav">
                <Nav.Item>
                  <Nav.Link eventKey="monthlyPlans">{t("monthly")}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="yearlyPlans">{t("yearly")}</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            <Tab.Content>
              <Tab.Pane eventKey="monthlyPlans">
                <div className="d-flex justify-content-center">
                  <PlansCardsforMonths />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="yearlyPlans">
                <div className="d-flex justify-content-center">
                  <PlanCardsforYears />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      </section> */}
      <section className="ready-start">
        <Container>
          <h2 className="text-center mb-4">{t("readytogetstarted")}</h2>
          <div className="d-flex justify-content-center gap-4">
            {/* <PFButton
              variant="secondary-bordered"
              onClick={signInWithGoogle}
              style={{ fontWeight: "800" }}
            >
              {t("signin")}
            </PFButton>
            <PFButton
              style={{ fontWeight: "800" }}
              variant="primary"
              onClick={signInWithGoogle}
            >
              {t("signup")}
            </PFButton> */}
            <Image
              src={GoogleLogo}
              width={200}
              height={50}
              onClick={signInWithGoogle}
              style={{ cursor: "pointer" }}
            />
          </div>
        </Container>
      </section>
    </div>
  );
}
