import React, { useEffect, useRef, useState } from "react";
import Logo from "./../../assets/images/logos/logo.svg";
import Credits1 from "../../assets/images/icons/lemon-slice.svg";
import { Container, Image, Dropdown, Spinner, Button } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { PFButton } from "../PFButton";
import { PFModal } from "../PFModal";
import useOnClickOutside from "./../../hooks/useOnClickOutside";
import "../../components/PFModal/PlansModal.css";
import "./Header.css";
import { db, signInWithGoogle, signOutGoogle } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import ChangePlanModal from "../model/ChangePlanModal";
import { useTranslation } from "react-i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import GoogleLogo from "../../assets/Google__G__Logo.svg.webp";
import { ReactComponent as Credits } from "../../assets/images/icons/credits.svg";
export function Header({ isLoggedIn }) {
  const [showNumberOfFormsModal, setShowNumberOfFormsModal] = useState(false);
  const [numberOfFormsModallogin, setNumberOfFormsModallogin] = useState(false);
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [value, setValue] = useState(localStorage.getItem("language"));
  const { t, i18n } = useTranslation("common");
  const [formNumber, setFormNumber] = useState("");
  const [formLoder, setFormLoder] = useState(false);
  const [planeType, setPlaneType] = useState("");

  const handleSelect = (e) => {
    window.localStorage.setItem("language", e);
    // console.log(window.location.pathname); //yields: "/js" (where snippets run)

    i18n.changeLanguage(e);
    setValue(e);
    // redirect user from here based on the language
  };

  useEffect(() => {
    const language = window.location.pathname;
    if (language === "/ca") {
      window.localStorage.setItem("language", "ca");
      i18n.changeLanguage("ca");

      setValue("ca");
      window.location = "/";
      // return <Navigate replace to="/login" />;
    } else if (language === "/en") {
      i18n.changeLanguage("en");
      window.localStorage.setItem("language", "en");
      setValue("en");
      window.location = "/";
    } else if (language === "/es") {
      i18n.changeLanguage("sp");
      window.localStorage.setItem("language", "sp");
      setValue("sp");
      window.location = "/";
    }
  }, []);

  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => handleMenuClose());
  const handleMenuToggle = () => {
    document.documentElement.classList.toggle("menu-open");
  };
  const handleMenuClose = () => {
    document.documentElement.classList.remove("menu-open");
  };
  const logOuthandler = () => {
    signOutGoogle();
    handleMenuClose();
  };
  const signInGoogle = () => {
    signInWithGoogle();
    handleMenuClose();
  };

  useEffect(() => {
    (async () => {
      setFormLoder(true);
      try {
        const user = JSON.parse(window.localStorage.getItem("USER"));

        const citiesRef = doc(
          db,
          `users`,
          user.uid,
          `FreeTrialYAMMY`,
          "formNumberCreate"
        );

        onSnapshot(citiesRef, (docSnap) => {
          if (docSnap.exists()) {
            setPlaneType(docSnap.data().planType);
            if (docSnap.data().planType === "YAMMY") {
              setFormNumber(docSnap.data().totalForm);
            } else if (docSnap.data().planType === "delicious") {
              if (docSnap.data().totalForm >= 0) {
                setFormNumber(docSnap.data().totalForm);
              } else {
                setFormNumber(docSnap.data().Current_form);
              }
            } else {
              setFormNumber(docSnap.data().Current_form);
            }
            setFormLoder(false);
          }
        });
      } catch (error) {
        setFormLoder(false);
        console.log(error);
      }
      setFormLoder(false);
    })();
  }, []);

  const NumberOfFormsModal = () => {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <Image src={Credits1} width={60} height={64} className="me-2" fluid />
          <span className="display-1">
            {formNumber !== "" ? formNumber : 0}
          </span>
        </div>
        <div className="mb-3">
          <h6 className="p dark">
            {planeType === "YAMMY" &&
              t("withyouryummyplanyouhave5freeformstousein3months")}
            {planeType === "delicious" &&
              t("withyouryummyplanyouhave25formstousein1months")}
            {planeType === "juicy" &&
              t("With your JUICY plan, you will be charged 0,50€ per form.")}
          </h6>
        </div>
        <div className="d-flex flex-wrap gap-3 mt-4">
          <PFButton
            variant={"dark"}
            size="small"
            // className={"fw-normal"}
            handleClick={() => {
              setShowNumberOfFormsModal(false);
              navigate("/create-form");
            }}
            style={{ fontWeight: "100" }}
          >
            {t("startcreating")}
          </PFButton>
          {/* <PFButton
            variant={'dark'}
            size="small"
            className={"fw-normal"}
            handleClick={() => {
              setShowNumberOfFormsModal(false);
              setShowChangePlanModal(true);
            }}
          >
            {t('upgradeplan')}
          </PFButton> */}
          <PFButton
            variant={"dark-bordered"}
            size="small"
            className={"fw-normal"}
            handleClick={() => setShowNumberOfFormsModal(false)}
          >
            {t("close")}
          </PFButton>
        </div>
      </>
    );
  };
  const NumberOfFormsModallogin = () => {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <Image src={Credits1} width={60} height={64} className="me-2" fluid />
          <span className="display-1">5</span>
        </div>
        <div className="mb-3">
          <h6 className="p dark">
            {t("withyouryummyplanyouhave5freeformstousein3months")}
          </h6>
        </div>
        <div className="d-flex flex-wrap gap-3 mt-4">
          <PFButton
            variant={"dark"}
            size="small"
            className={"fw-normal"}
            handleClick={() => {
              setShowNumberOfFormsModal(false);
              signInGoogle();
            }}
            style={{ fontWeight: "100" }}
          >
            {t("startcreating")}
          </PFButton>
          {/* <PFButton
            variant={'dark'}
            size="small"
            className={'fw-normal'}
            handleClick={() => {
              setShowNumberOfFormsModal(false);
              setShowChangePlanModal(true);
            }}
          >
            {t('upgradeplan')}
          </PFButton> */}
          <PFButton
            variant={"dark-bordered"}
            size="small"
            className={"fw-normal"}
            handleClick={() => setShowNumberOfFormsModal(false)}
          >
            {t("close")}
          </PFButton>
        </div>
      </>
    );
  };
  return (
    <>
      <header className="header">
        <Container>
          <div className="header-inner">
            <Link className="d-block brand-logo" to={"/"}>
              <Image
                src={Logo}
                width={211}
                height={34}
                alt="Peasy Forms"
                fluid
              />
            </Link>
            <button className="hamburger d-md-none" onClick={handleMenuToggle}>
              <div></div>
            </button>
            {!isLoggedIn ? (
              <ul className="header-nav list-unstyled" ref={dropdownRef}>
                <li className="nav-item">
                  <a
                    href="#howitworks"
                    className="link"
                    onClick={handleMenuClose}
                  >
                    {t("howitworks")}
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a href="#pricing" className="link" onClick={handleMenuClose}>
                    {t("pricing")}
                  </a>
                </li> */}

                {/* <li className="nav-item creds-nav-item order-5 order-md-4">
                  <button
                    className="link button-flushed avl-credits"
                    onClick={() => {
                      handleMenuClose();
                      setNumberOfFormsModallogin(true);
                    }}
                  >
                    <Credits />
                    <div className="credits-count dark-purple ms-2">
                      5 <span className="d-md-none">{t('freecredits')}</span>
                    </div>
                  </button>
                </li> */}
                <li className="nav-item  order-md-5">
                  <Button
                    onClick={signInGoogle}
                    style={{
                      padding: "10px 22px 10px 22px",
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid lightgray",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src={GoogleLogo}
                      width={15}
                      height="auto"
                      style={{ cursor: "pointer" }}
                    />
                    <span style={{ marginLeft: 12 }}>Sign in with Google</span>
                  </Button>
                </li>
                <Dropdown
                  id="nav-dropdown-dark-example"
                  title={"en"}
                  variant="white"
                  onSelect={(e) => handleSelect(e)}
                >
                  <Dropdown.Toggle variant="white">
                    {/* {value === "en" ? (
                      <span className="fi fi-gb"></span>
                    ) : (
                      <span className="fi fi-es"></span>
                    )} */}
                    {value === "en" ? (
                      <span className="fi fi-gb"></span>
                    ) : value === "ca" ? (
                      <span className="fi fi-es-ct"></span>
                    ) : (
                      <span className="fi fi-es"></span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <Dropdown.Item eventKey={"en"} className="dropdown-item">
                      <span
                        className="fi fi-gb"
                        style={{ marginRight: "10px" }}
                      ></span>
                      English
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={"sp"} className="dropdown-item">
                      <span
                        className="fi fi-es"
                        style={{ marginRight: "10px" }}
                      ></span>
                      Spanish
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={"ca"} className="dropdown-item">
                      <span
                        className="fi fi-es-ct"
                        style={{ marginRight: "10px" }}
                      ></span>
                      Catalan
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            ) : (
              <ul className="header-nav list-unstyled" ref={dropdownRef}>
                {/* <li className="nav-item">
                  <NavLink
                    to="/manage-account"
                    className={({ isActive }) => {
                      return isActive ? "link active" : "link";
                    }}
                    onClick={handleMenuClose}
                  >
                    {t("manageaccount")}
                  </NavLink>
                </li> */}
                <Dropdown
                  id="nav-dropdown-dark-example"
                  title={"en"}
                  variant="white"
                  onSelect={(e) => handleSelect(e)}
                >
                  <Dropdown.Toggle variant="white">
                    {/* {value === "en" ? (
                      <span className="fi fi-gb"></span>
                    ) : (
                      <span className="fi fi-es"></span>
                    )} */}
                    {value === "en" ? (
                      <span className="fi fi-gb"></span>
                    ) : value === "ca" ? (
                      <span className="fi fi-es-ct"></span>
                    ) : (
                      <span className="fi fi-es"></span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <Dropdown.Item eventKey={"en"} className="dropdown-item">
                      <span
                        className="fi fi-gb"
                        style={{ marginRight: "10px" }}
                      ></span>
                      English
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={"sp"} className="dropdown-item">
                      <span
                        className="fi fi-es"
                        style={{ marginRight: "10px" }}
                      ></span>
                      Spanish
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={"ca"} className="dropdown-item">
                      <span
                        className="fi fi-es-ct"
                        style={{ marginRight: "10px" }}
                      ></span>
                      Catalan
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <li className="nav-item creds-nav-item order-5 order-md-4">
                  <button
                    className="link button-flushed avl-credits"
                    // onClick={() => {
                    //   handleMenuClose();
                    //   setShowNumberOfFormsModal(true);
                    // }}
                  >
                    <Image
                      src={Credits1}
                      width={22}
                      height={22}
                      className="me-2"
                      fluid
                    />
                    <div className="credits-count dark-purple ms-2">
                      {formLoder ? (
                        <Spinner size="sm" animation="border" />
                      ) : formNumber !== "" ? (
                        formNumber
                      ) : (
                        0
                      )}
                      {/* <span className="d-md-none"> {t("freecredits")}</span> */}
                    </div>
                  </button>
                </li>

                <li className="nav-item order-4 order-md-5">
                  <PFButton
                    variant={"secondary"}
                    size={"small"}
                    className={"flex-shrink-0"}
                    handleClick={logOuthandler}
                    style={{ padding: "9px 8px", fontWeight: "500" }}
                  >
                    {t("logout")}
                  </PFButton>
                </li>
              </ul>
            )}
          </div>
        </Container>
      </header>
      <PFModal
        isOpen={showNumberOfFormsModal}
        handleClose={() => setShowNumberOfFormsModal(false)}
      >
        <NumberOfFormsModal />
      </PFModal>
      <PFModal
        isOpen={showChangePlanModal}
        size="large"
        handleClose={() => setShowChangePlanModal(false)}
      >
        <ChangePlanModal setShowChangePlanModal={setShowChangePlanModal} />
      </PFModal>
      <PFModal
        isOpen={numberOfFormsModallogin}
        handleClose={() => setNumberOfFormsModallogin(false)}
      >
        <NumberOfFormsModallogin />
      </PFModal>
    </>
  );
}
