import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Container, Image, Row, Spinner } from "react-bootstrap";
import {
  PFButton,
  PFInput,
  PFModal,
  PFTextArea,
  SVGIcons,
} from "../../components";
import TemplatePreview from "../../assets/images/template-preview.svg";
import LoaderStep1 from "../../assets/images/icons/loader-step-1.svg";
import LoaderStep2 from "../../assets/images/icons/loader-step-2.svg";
import LoaderStep3 from "../../assets/images/icons/loader-step-3.svg";
import LoaderStep4 from "../../assets/images/icons/loader-step-4.svg";
import Credits from "../../assets/images/icons/lemon-slice.svg";
import { browserName } from "react-device-detect";
import "swiper/css";
import "swiper/css/pagination";
import "./CreateForm.css";
import { client_id, client_secret } from "../../API/GoogleOauthApi";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import FormPreview from "../FormPreview/FormPreview";
import EnterPaymentDetailsModal from "../../components/model/EnterPaymentDetailsModal";
import ChangePlanModal from "../../components/model/ChangePlanModal";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { ReactComponent as PencileIcon } from "../../assets/images/icons/pencile.svg";
import { ReactComponent as HandIcon } from "../../assets/images/icons/handIcon.svg";
import Template6 from "../../assets/images/templates/membership.png";
import Template7 from "../../assets/images/templates/customer.png";
import Template8 from "../../assets/images/templates/registration.png";
import LinkWithGoogleModal from "../../components/model/LinkWithGoogleModal";
import HorizontalStepper from "./HorizontalStepper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Copyicon } from "../../assets/images/icons/copy.svg";
import { typeFormclientId, typeFormclientSecret } from "../../stripe/api";
import { json, useNavigate } from "react-router-dom";
import TokenOverModel from "../../components/model/TokenOverModel";

export default function CreateForm() {
  const { t, i18n } = useTranslation("common");
  const navigate = useNavigate();

  const placeholders = [
    {
      id: 1,
      text: `${t(
        "aformtocollectrequireddatatoinformofacaraccidentforaninsurancecompany"
      )}`,
    },
    {
      id: 2,
      text: `${t("ineedtounderstandbetterthewayusersusemysoftware")}`,
    },
    {
      id: 3,
      text: `${t(
        "aformaskingforthedietdetailsofapersonthatshouldincludeatleastquestions"
      )}`,
    },
    {
      id: 4,
      text: `${t("iwanttofindoutwhatmyemployeesthinkaboutmymanagementskills")}`,
    },
    {
      id: 5,
      text: `${t("aformforamotorcyclesatisfactionsurvey")}`,
    },
    {
      id: 6,
      text: `${t(
        "iwanttounderstandthepointsofimprovementformyrestaurantMakeitanonymous"
      )}`,
    },
    {
      id: 7,
      text: `${t(
        "aquestionnairewithatleastquestionstotesttheusersknowledgeonMozart"
      )}`,
    },
    {
      id: 8,
      text: `${t("aformcollectingdataforyearlytaxdeclarationinSpain")}`,
    },
    {
      id: 9,
      text: `${t(
        "aquestionnaireforstudentsofadriversacademytestingtheirunderstandingofEuropedrivingregulationsTheformshouldcontainatleastchoicefields"
      )}`,
    },
    {
      id: 10,
      text: `${t("createaformforasalesrepresentativejobapplication1")}`,
    },
    {
      id: 11,
      text: `${t(
        "ineedaformtoobtaintheworkerpreferencesforthenewdesignofmyshop"
      )}`,
    },
    {
      id: 12,
      text: `${t(
        "iwantaformfororderinganewproductTheformshouldincludethenumberofitemstopurchase"
      )}`,
    },
    {
      id: 13,
      text: `${t(
        "aquestionnaireforstartupsapplyingtoaventurecapitalinvestment"
      )}`,
    },
    {
      id: 14,
      text: `${t(
        "aformwithnecessaryinformationforreturningadamagedmobilephoneinanonlineshopIncludeallpossiblesituationsandthecorrespondingactions"
      )}`,
    },
    {
      id: 15,
      text: `${t(
        "aquestionnairetoteststudentsunderstandingofthemainelementsofNewtonmethod"
      )}`,
    },
  ];
  const loaderRef = useRef(null);

  const [showLoader, setShowLoader] = useState(false);
  const [loaderStep, setLoaderStep] = useState(1);
  const [formName, setFormName] = useState("");
  const [transcript, setTranscript] = useState("");
  const [transcript1, setTranscript1] = useState("");
  const [listening, setListening] = useState(false);
  const [formData, setFormData] = useState();
  const [showContinuePayment, setShowContinuePayment] = useState(false);
  const [showNumberOfFormsModal, setShowNumberOfFormsModal] = useState(false);
  const [showNumberOfForms25Modal, setShowNumberOfForms25Modal] =
    useState(false);
  const [plane, setPlane] = useState("");
  const [NumberOfForms25, setNumberOfForms25] = useState(false);
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [isLoddingTypeform, setIsLoddingTypeform] = useState(false);
  const [placeholder, setPlasholder] = useState("");
  const [showLinkWithPopup, setShowLinkWithPopup] = useState(false);
  const [showFormReadyModal, setShowFormReadyModal] = useState(false);
  const [link, setLink] = useState();
  const [tokenOver,setTokenOver]=useState(false)

  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    const a = Math.floor(Math.random() * (max - min + 1)) + min;
    const object = placeholders?.find((obj) => obj.id === a);
    if (object) {
      setPlasholder(object.text);
    }
  }, [i18n.language]);

  const min = 1;
  const max = 15;
  const getTypeFormToken = async () => {
    const user = JSON.parse(window.localStorage.getItem("USER"));

    try {
      const colRef = doc(db, `users/${user.uid}/GoogleLinkToken/Typeformtoken`);
      const docSnap = await getDoc(colRef);
      if (docSnap.exists()) {
        localStorage.setItem(
          "TypeForm_accessToken",
          docSnap.data().Typeform_access_token
        );
      }
    } catch (e) {}
  };
  useEffect(() => {
    (async () => {
      getTypeFormToken();

      const user = JSON.parse(window.localStorage.getItem("USER"));

      try {
        const colRef = doc(db, `users/${user.uid}/GoogleLinkToken/token`);
        const docSnap = await getDoc(colRef);
        if (docSnap.exists()) {
          localStorage.setItem("accessToken", docSnap.data().access_token);
          localStorage.setItem("refreshToken", docSnap.data().refresh_token);
        }
      } catch (e) {}
    })();
  }, []);
  const paymaentHandelar = () => {
    setShowContinuePayment(false);
    setShowLoader(false);
  };
  useEffect(() => {
    loaderRef.current = loaderStep;
  }, [loaderStep]);

  const getFromFiledData = (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      token: "98lKo4XMJJhz-kOXzWfYp7OuN?LN46L9rbLGkL7YFHHn5WlCc8Y8GROVoSAXThNF",
      language: "en-US",
      text: transcript || transcript1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://peasyforms.processtalks.com/form", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.status !== "OK") {
          setShowLoader(false);
          toast.error(`${t("Entermoretext")}`);
        } else {
          const user = JSON.parse(window.localStorage.getItem("USER"));
          const colRef = doc(
            db,
            `users/${user.uid}/FreeTrialYAMMY/formNumberCreate`
          );
          // console.log(result.output, "data1assasas");
          localStorage.setItem("fromName", result.output.form.title);
          setFormName(result.output.form.title);

          const data1 = result.output.form["input-fields"];
  
          data1.map((obj, index) => {
            if (obj.type === "date_range") {
              data1.splice(index + 1, 0, {
                label: `${obj.label}(End)`,
                motivation: obj.motivation,
                name: obj.name,
                required: obj.required,
                type: "date",
              });
              obj.type = "date";
              obj.label = `${obj.label}(Start)`;
            }
            return obj;
          });
          setFormData(data1);
          setActiveStep(2);
          perDaygeneratedFrom();
          newUserCreateForm();
          updateDoc(colRef, {
            Current_form: data.Current_form + 1,
            // formField: [...data.formField, result],
            totalForm: data.totalForm - 1,
          })
            .then((docRef) => {
              setShowLoader(false);
            })
            .catch((error) => {
              setShowLoader(false);
              console.log(error);
            });
          setShowLoader(false);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        console.log("error", error);
      });
  };
  const isPlans = async () => {
    const user = JSON.parse(window.localStorage.getItem("USER"));
    const date = new Date();
    date.setDate(date.getDate() + 90);
    try {
      const colRef = doc(
        db,
        `users/${user.uid}/FreeTrialYAMMY/formNumberCreate`
      );

      const docSnap = await getDoc(colRef);
      if (docSnap.exists()) {
        if (
          docSnap.data().planType === "YAMMY" &&
          docSnap.data().status !== "deactived"
        ) {
          if (docSnap.data().totalForm !== 0) {
            getFromFiledData(docSnap.data());
          } else {
            setTokenOver(true)
            setShowLoader(false);
            // updateDoc(colRef, {
            //   // status: "deactived",
            // })
            //   .then((docRef) => {
            //     setShowLoader(false);
            //     // setShowNumberOfFormsModal(true);
            //   })
            //   .catch((error) => {
            //     setShowLoader(false);
            //     console.log(error);
            //   });
          }
        }
        //  else if (
        //   docSnap.data().customerId === "" &&
        //   docSnap.data().Current_form === 5 &&
        //   docSnap.data().planType === "YAMMY"
        // ) {
        //   setShowLoader(false);
        //   // setShowNumberOfFormsModal(true);
        // }
        // else if (
        //   docSnap.data().planType === "delicious" &&
        //   docSnap.data().paidPlan === true
        // ) {
        //   if (docSnap.data().Current_form === 25) {
        //     setNumberOfForms25(docSnap.data());
        //     setShowLoader(false);
        //     setShowNumberOfForms25Modal(true);
        //     // toast.success(
        //     //   `${t('ifcreatemorethan25formyouhavetopayeachform0.2eur')}`
        //     // );
        //   } else {
        //     if (docSnap.data().customerId === "") {
        //       setShowLoader(false);
        //       setShowContinuePayment(true);
        //     } else {
        //       getFromFiledData(docSnap.data());
        //     }
        //   }
        // } else if (
        //   docSnap.data().planType === "juicy" &&
        //   docSnap.data().paidPlan === true
        // ) {
        //   if (docSnap.data().customerId === "") {
        //     setShowLoader(false);
        //     setShowContinuePayment(true);
        //   } else {
        //     getFromFiledData(docSnap.data());
        //   }
        // }
        else if (
          docSnap.data().planType === "" &&
          docSnap.data().paidPlan === false
        ) {
          setShowLoader(false);
          setTokenOver(true)

          //   setShowChangePlanModal(true);
        }
      } else {
        setShowLoader(false);
        // setShowNumberOfFormsModal(true);
      }
    } catch (e) {
      setShowLoader(false);
      console.log(e);
    }
  };
  const handleCreateForm = (event) => {
    event.preventDefault();
    if (transcript === "") {
      toast.error(`${t("Enter some text")}`);
    } else {
      isPlans();
      setShowLoader(true);
      let interval = setInterval(() => {
        if (loaderRef.current !== 4) {
          setLoaderStep((prev) => prev + 1);
        } else {
          setLoaderStep(1);
          clearInterval(interval);
        }
      }, 2000);
    }
  };
  const authorize = () => {
    const clientId = typeFormclientId;
    const redirectUri = window.location.origin; // Customize this with your desired redirect URI
    const responseType = "code";
    const authorizationUrl = `https://api.typeform.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=forms:read+forms:write`;
    window.location.href = authorizationUrl;
  };
  const urlParams = new URLSearchParams(window.location.search);
  const authorizationCode = urlParams.get("code");
  useEffect(() => {
    // console.log(authorizationCode);
    authorizationCode && getAccesstoken(authorizationCode);
  }, []);

  const getAccesstoken = (code) => {
    setIsLoddingTypeform(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      authorizationCode: code,
      clientId: typeFormclientId,
      clientSecret: typeFormclientSecret,
      redirectUri: window.location.origin,
      isRefreshToken: false,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        "https://europe-west3-process-talks-forms.cloudfunctions.net/getOauthToken",
        requestOptions
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
          }
          // Handle the response
        })
        .then(async (val) => {
          if (val) {
            setFormData(JSON.parse(localStorage.getItem("API_Data")));
            localStorage.setItem("TypeForm_accessToken", val.accessToken);
            addTypeformToken(val.accessToken);
            callApiTypeform();
            navigate("/");
            setActiveStep(2);
            setIsLoddingTypeform(false);
          }
        })
        .catch((error) => {
          setFormData(JSON.parse(localStorage.getItem("API_Data")));
          console.log("error", error);
          setIsLoddingTypeform(false);
        });
    } catch (e) {
      setFormData(JSON.parse(localStorage.getItem("API_Data")));
      setIsLoddingTypeform(false);

      console.log(e);
    }
  };
  const addTypeformToken = async (access_token) => {
    const user = JSON.parse(window.localStorage.getItem("USER"));
    const docRef = doc(
      db,
      "users",
      user.uid,
      "GoogleLinkToken",
      "Typeformtoken"
    );
    setDoc(docRef, {
      Typeform_access_token: access_token,
    }).catch((e) => {
      console.log(e);
    });
  };
  const perDaygeneratedTypeFrom = async () => {
    const docRef = doc(db, "formData", "everyDayEmailData");
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, {
          perDatTotalTypeFrom: docSnap.data().perDatTotalTypeFrom + 1,
          TotalTypeFrom: docSnap.data().TotalTypeFrom + 1,
        });
      } else {
        setDoc(docRef, {
          perDatTotalTypeFrom: 1,
          TotalTypeFrom: 1,
          newUserTotalTypeFrom: 1,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const newUserCreateTypeFrom = async () => {
    const user = JSON.parse(window.localStorage.getItem("USER"));
    const docRef = doc(db, "newUser", "newUserData");

    const newUserGet = await getDoc(docRef);
    if (newUserGet.exists) {
      try {
        const userId = newUserGet.data().uid;
        const foundUser = userId.find((element) => element === user.uid);
        if (foundUser) {
          const docRef = doc(db, "formData", "everyDayEmailData");

          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            await updateDoc(docRef, {
              newUserTotalTypeFrom: docSnap.data().newUserTypeFrom + 1,
            });
          } else {
            setDoc(docRef, {
              newUserTotalTypeFrom: 1,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const CreteTypeForm = (typeformdata) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "accessToken",
      `Bearer ${localStorage.getItem("TypeForm_accessToken")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: typeformdata,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://europe-west3-process-talks-forms.cloudfunctions.net/createTypeForm",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((result) => {
        if (result) {
          setLink(result?.data?._links?.display);
          setShowFormReadyModal(true);
          setActiveStep(3);
          setShowLinkWithPopup(false);
          newUserCreateTypeFrom();
          perDaygeneratedTypeFrom();

          // setIsLodding(false);
        } else {
          // setIsLodding(false);
        }
      })
      .catch((error) => {
        // setIsLodding(false);
        console.log("error", error);
      });
  };
  const callApiTypeform = () => {
    let arr = [];
    const data = JSON.parse(localStorage.getItem("API_Data"));
    data.forEach((element, index) => {
      if (element.type === "text") {
        arr.push({
          title: element.label,
          type: "short_text",
          validations: {
            // max_length: 50,
            required: element.required ? true : false,
          },
        });
      } else if (element.type === "text_area") {
        arr.push({
          title: element.label,
          type: "long_text",
          validations: {
            required: element.required ? true : false,
          },
        });
      } else if (element.type === "number") {
        arr.push({
          title: element.label,
          type: "number",
          validations: {
            required: element.required ? true : false,
          },
        });
      }
      // 3aqsVSEqxi1jQTYSk8LJRXhRFonEvX4C673ZYWPvpKHX
      //  else if (element.type === "file") {
      //   arr.push({
      //     title: "Upload File",
      //     type: "file_upload",
      //     ref: "fileUploadField",
      //     properties: {
      //       description: "Please upload your file",
      //     },
      //   });
      // }
      else if (element.type === "time") {
        arr.push({
          title: element.label,
          type: "short_text",
          validations: {
            // max_length: 50,
            required: element.required ? true : false,
          },
        });
      } else if (element.type === "password") {
        arr.push({
          title: element.label,
          type: "short_text",
          validations: {
            // max_length: 50,
            required: element.required ? true : false,
          },
        });
      } else if (element.type === "date") {
        arr.push({
          properties: {
            separator: "-",
            structure: "DDMMYYYY",
          },
          title: element.label,
          type: "date",
          validations: {
            required: element.required ? true : false,
          },
        });
      } else if (element.type === "choice") {
        const array = [];
        element.options.forEach((i) => {
          array.push({
            label: i,
          });
        });
        arr.push({
          properties: {
            // alphabetical_order: false,
            choices: array,
            // description: "Cool description for the dropdown",
            // randomize: false,
          },
          // ref: "nice_readable_dropdown_reference",
          title: element.label,
          type: "dropdown",
          validations: { required: element.required ? true : false },
        });
      } else if (element.type === "multiple_choice") {
        const array = [];
        element.options.forEach((i) => {
          array.push({
            label: i,
          });
        });
        arr.push({
          properties: {
            allow_multiple_selection: true,
            allow_other_choice: false,
            choices: array,
            randomize: true,
            vertical_alignment: false,
          },
          title: element.label,
          type: "multiple_choice",
          validations: {
            required: element.required ? true : false,
          },
        });
      } else if (element.type === "checkbox") {
        const array = [];
        element.options.forEach((i) => {
          array.push({
            label: i,
          });
        });
        arr.push({
          properties: {
            allow_multiple_selection: true,
            allow_other_choice: false,
            choices: array,
            randomize: true,
            vertical_alignment: false,
          },
          title: element.label,
          type: "multiple_choice",
          validations: {
            required: element.required ? true : false,
          },
        });
      }
    });

    var formData = {
      title: localStorage.getItem("fromName"),
      fields: arr,
      thankyou_screens: [
        {
          // attachment: {
          //   href: ,
          //   type: "image",
          // },
          properties: {
            button_mode: "redirect",
            button_text: "Go to PeasyFoms",
            redirect_url: "https://peasyforms.com/",
            share_icons: false,
            show_button: true,
          },
          type: "thankyou_screen",
          ref: "nice-readable-thank-you-ref",
          title: "Thank you ",
        },
      ],
    };

    CreteTypeForm(formData);
  };
  const templateSliderSettings = {
    modules: [Pagination],
    spaceBetween: 10,
    slidesPerView: 1,
    pagination: {
      el: ".custom-pagination",
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 1,
      },
    },
  };
  if (browserName === "Chrome") {
    var recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = "en-US";
    recognition.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      setTranscript(transcript);
      setTranscript1(transcript1);
      setActiveStep(1);
    };
  }
  const toggleListen = () => {
    setListening(true);
    recognition.start();
    setTimeout(function () {
      recognition.stop();
      setListening(false);
    }, 10000);
  };
  const perDaygeneratedFrom = async () => {
    const docRef = doc(db, "formData", "everyDayEmailData");
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, {
          perDatTotalFrom: docSnap.data().perDatTotalFrom + 1,
          TotalForms: docSnap.data().TotalForms + 1,
        });
      } else {
        setDoc(docRef, {
          perDatTotalFrom: 1,
          TotalForms: 1,
          newUserTotalform: 1,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const newUserCreateForm = async () => {
    const user = JSON.parse(window.localStorage.getItem("USER"));
    const docRef = doc(db, "newUser", "newUserData");

    const newUserGet = await getDoc(docRef);
    if (newUserGet.exists) {
      try {
        const userId = newUserGet.data().uid;
        const foundUser = userId.find((element) => element === user.uid);
        if (foundUser) {
          const docRef = doc(db, "formData", "everyDayEmailData");

          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            await updateDoc(docRef, {
              newUserTotalform: docSnap.data().newUserTotalform + 1,
            });
          } else {
            setDoc(docRef, {
              newUserTotalform: 1,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const NumberOfFormsModal = () => {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <Image src={Credits} width={60} height={64} className="me-2" fluid />
          <span className="display-1">0</span>
        </div>

        <div className="mb-3">
          <h6 className="p dark">{t("youhavenoformsleft")}</h6>
          <h6 className="p dark">
            {t(
              "To continue using Peasy Forms you need to choose the plan that"
            )}
          </h6>
          <h6 className="p dark">{t("bestsuitsforyou")}</h6>
        </div>

        <div className="d-flex flex-wrap gap-3 mt-4">
          <PFButton
            variant={"dark"}
            size="small"
            // className={"fw-normal"}
            handleClick={() => {
              setShowNumberOfFormsModal(false);
              setShowChangePlanModal(true);
            }}
            style={{ fontWeight: "100" }}
          >
            {t("Choose your plan")}
          </PFButton>
          <PFButton
            variant={"dark-bordered"}
            size="small"
            // className={"fw-normal"}
            handleClick={() => setShowNumberOfFormsModal(false)}
            style={{ fontWeight: "100" }}
          >
            {t("close")}
          </PFButton>
        </div>
      </>
    );
  };
  const NumberOfForms25Modal = () => {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <Image src={Credits} width={60} height={64} className="me-2" fluid />
          <span className="display-1">25</span>
        </div>

        <div className="mb-3">
          <h6 className="p dark">
            {t("ifcreatemorethan25formyouhavetopayeachform0.2eur")}
          </h6>
        </div>

        <div className="d-flex flex-wrap gap-3 mt-4">
          <PFButton
            variant={"dark"}
            size="small"
            // className={"fw-normal"}
            handleClick={() => {
              setShowLoader(true);
              setShowNumberOfForms25Modal(false);
              getFromFiledData(NumberOfForms25);
            }}
            style={{ fontWeight: "100" }}
          >
            {t("startcreating")}
          </PFButton>
          <PFButton
            variant={"dark-bordered"}
            size="small"
            className={"fw-normal"}
            handleClick={() => setShowNumberOfForms25Modal(false)}
          >
            {t("close")}
          </PFButton>
        </div>
      </>
    );
  };
  return (
    <>
      <div
        className={`loader-wrapper ${
          showLoader === true ? "d-flex" : "d-none"
        }`}
      >
        <div className={`loader-step ${loaderStep === 1 ? "active" : ""}`}>
          <div className="loder-image">
            <Image src={LoaderStep1} width={95} fluid />
            <p style={{ fontSize: "18px", fontWeight: 400 }}>
              {t("Cutting the lemons")}
            </p>
          </div>
        </div>
        <div className={`loader-step ${loaderStep === 2 ? "active" : ""}`}>
          <div className="loder-image">
            <Image src={LoaderStep2} width={95} fluid />
            <p style={{ fontSize: "18px", fontWeight: 400 }}>
              {t("Squeezing them")}
            </p>
          </div>
        </div>
        <div className={`loader-step ${loaderStep === 3 ? "active" : ""}`}>
          <div className="loder-image">
            <Image src={LoaderStep3} width={95} fluid />
            <p style={{ fontSize: "18px", fontWeight: 400 }}>
              {t("Adding some ice")}
            </p>
          </div>
        </div>
        <div className={`loader-step ${loaderStep === 4 ? "active" : ""}`}>
          <div className="loder-image">
            <Image src={LoaderStep4} width={95} fluid />
            <p style={{ fontSize: "18px", fontWeight: 400 }}>
              {t("Your form is almost ready")}
            </p>
          </div>
        </div>
      </div>
      <HorizontalStepper currentStep={activeStep} />

      {!formData && (
        <>
          {!isLoddingTypeform ? (
            <Container>
              <br />
              <div className="row"></div>

              <main>
                <br />
                <h5 className="fw-bold dark-blue mb-4 font-weight-bold">
                  {t("writehere")}
                  <span className="h3 ms-2">
                    <PencileIcon widths="25px" height="25px" />
                    <HandIcon widths="25px" height="25px" />
                  </span>
                </h5>
                <Row className="gy-5 gy-lg-0">
                  <Col lg={8}>
                    <form onSubmit={handleCreateForm}>
                      {/* <PFInput
                      require={true}
                      id={"name-form"}
                      name={"name-form"}
                      label={`${t("nameyourform")}`}
                      parentClassName='mb-4'
                      placeholder={`${t("formsnames")}`}
                      floatingLabel
                      value={formName}
                      handleChange={handleChangeFormName}
                    /> */}

                      <div className="position-relative recordable-textarea mb-4">
                        <PFTextArea
                          id={"name-form"}
                          name={"name-form"}
                          label={`${t("createyourform")}`}
                          rows={16}
                          placeholder={placeholder}
                          floatingLabel
                          handleChange={(e) => {
                            setTranscript(e.target.value);
                            setActiveStep(1);
                          }}
                          value={transcript}
                        />
                        {browserName === "Chrome" && (
                          <p
                            className="button-flushed record-using-mic p-sm fw-medium d-flex align-items-center"
                            style={{ color: "#3737b8", cursor: "pointer" }}
                            onClick={toggleListen}
                          >
                            {listening ? (
                              "....."
                            ) : (
                              <span>{SVGIcons.Microphone}</span>
                            )}
                            <span
                              className="d-none d-sm-block ms-2"
                              style={{ fontWeight: "500" }}
                            >
                              Record using microphone
                            </span>
                          </p>
                        )}
                      </div>

                      <PFButton
                        style={{
                          fontSize: "1.125rem",
                          fontWeight: "800",
                          backgroundColor: "#FBE54D",
                          borderColor: "#FBE54D",
                        }}
                        variant={"primary"}
                        type="submit"
                        // onClick={handleCreateForm}
                      >
                        {t("create!")}
                      </PFButton>
                    </form>
                  </Col>
                  <Col lg={4}>
                    <div>
                      <Swiper
                        {...templateSliderSettings}
                        className="template-slider"
                      >
                        <SwiperSlide>
                          <div
                            className="template-card"
                            style={{ height: "16rem", width: "303px" }}
                          >
                            <div
                              className="template-details"
                              style={{ marginTop: "2rem" }}
                            >
                              {/* <h6 className='fw-bold'>
                              {t("feedbackandevaluationforms1")}
                            </h6> */}
                              <p
                                className="mb-0 fw-normal"
                                style={{
                                  height: "4rem",
                                  padding: "0.8rem",
                                }}
                              >
                                {t(
                                  "makeaformtoaskforstylepreferencesinclothingstores"
                                )}
                              </p>
                              <PFButton
                                variant={"secondary-bordered"}
                                size={"small"}
                                style={{
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  marginTop: "4rem",
                                  marginLeft: "0.6rem",
                                  width: "16rem",
                                  whiteSpace: "nowrap",
                                }}
                                handleClick={(e) => {
                                  setActiveStep(1);
                                  setTranscript1(
                                    t(
                                      "makeaformtoaskforstylepreferencesinclothingstores"
                                    )
                                  );

                                  // setFormName(t("feedbackandevaluationforms1"));
                                  setTranscript(
                                    t(
                                      "makeaformtoaskforstylepreferencesinclothingstores"
                                    )
                                  );
                                }}
                              >
                                {t("usethistemplate")}
                                <Copyicon
                                  style={{
                                    fontSize: "1.5rem",
                                    marginLeft: "0.5rem",
                                  }}
                                />
                              </PFButton>
                            </div>
                            {/* <div className='template-image'>
                            <Image
                              src={TemplatePreview}
                              alt=''
                              width={175}
                              height={117}
                              fluid
                            />
                          </div> */}
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div
                            className="template-card"
                            style={{ height: "16rem", width: "303px" }}
                          >
                            <div
                              className="template-details"
                              style={{ marginTop: "2rem" }}
                            >
                              {/* <h6 className='fw-bold'>
                              {t("membershipandsubscriptionforms")}
                            </h6> */}
                              <p
                                className="mb-0 fw-normal"
                                style={{
                                  height: "4rem",
                                  padding: "0.8rem",
                                }}
                              >
                                {t(
                                  "aformtoevaluatestartupsapplyingtoanaccelerationprogram"
                                )}
                              </p>
                              <PFButton
                                variant={"secondary-bordered"}
                                size={"small"}
                                style={{
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  marginTop: "4.29rem",
                                  marginLeft: "0.6rem",
                                  width: "16rem",
                                  whiteSpace: "nowrap",
                                }}
                                handleClick={(e) => {
                                  setActiveStep(1);
                                  setTranscript1(
                                    t(
                                      "aformtoevaluatestartupsapplyingtoanaccelerationprogram"
                                    )
                                  );
                                  // setFormName(t("membershipandsubscriptionforms"));
                                  setTranscript(
                                    t(
                                      "aformtoevaluatestartupsapplyingtoanaccelerationprogram"
                                    )
                                  );
                                }}
                              >
                                {t("usethistemplate")}
                                <Copyicon
                                  style={{
                                    fontSize: "1.5rem",
                                    marginLeft: "0.5rem",
                                  }}
                                />
                              </PFButton>
                            </div>
                            {/* <div
                            className='template-image'
                            style={{
                              backgroundColor: "#ffdbf7",
                            }}
                          >
                            <Image
                              src={Template6}
                              alt=''
                              width={175}
                              height={117}
                              fluid
                            />
                          </div> */}
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div
                            className="template-card"
                            style={{ height: "16rem", width: "303px" }}
                          >
                            <div
                              className="template-details"
                              style={{ marginTop: "2rem" }}
                            >
                              {/* <h6 className='fw-bold'>
                              {t("customersatisfactionforms")}
                            </h6> */}
                              <p
                                className="mb-0 fw-normal"
                                style={{
                                  height: "4rem",
                                  padding: "0.8rem",
                                }}
                              >
                                {t(
                                  "iwanttodiscoverifmycustomersaresatisfiedwithmyproductsquality"
                                )}
                              </p>
                              <PFButton
                                variant={"secondary-bordered"}
                                size={"small"}
                                style={{
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  marginTop: "4.29rem",
                                  marginLeft: "0.6rem",
                                  width: "16rem",
                                  whiteSpace: "nowrap",
                                }}
                                handleClick={(e) => {
                                  setActiveStep(1);
                                  setTranscript1(
                                    t(
                                      "iwanttodiscoverifmycustomersaresatisfiedwithmyproductsquality"
                                    )
                                  );

                                  // setFormName(t("customersatisfactionforms"));
                                  setTranscript(
                                    t(
                                      "iwanttodiscoverifmycustomersaresatisfiedwithmyproductsquality"
                                    )
                                  );
                                }}
                              >
                                {t("usethistemplate")}
                                <Copyicon
                                  style={{
                                    fontSize: "1.5rem",
                                    marginLeft: "0.5rem",
                                  }}
                                />
                              </PFButton>
                            </div>
                            {/* <div
                            className='template-image'
                            style={{ backgroundColor: "#dafffd" }}
                          >
                            <Image
                              src={Template7}
                              alt=''
                              width={175}
                              height={117}
                              fluid
                            />
                          </div> */}
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div
                            className="template-card"
                            style={{ height: "16rem", width: "303px" }}
                          >
                            <div
                              className="template-details"
                              style={{ marginTop: "2rem" }}
                            >
                              {/* <h6 className='fw-bold'>
                              {t("contactandregistrationforms")}
                            </h6> */}
                              <p
                                className="mb-0 fw-normal "
                                style={{
                                  height: "4rem",
                                  padding: "0.8rem",
                                }}
                              >
                                {t(
                                  "ineedaformtoaskmyvisitorstosignuponmywebsite"
                                )}
                              </p>
                              <PFButton
                                variant={"secondary-bordered"}
                                size={"small"}
                                style={{
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  marginTop: "4rem",
                                  marginLeft: "0.6rem",
                                  width: "16rem",
                                  whiteSpace: "nowrap",
                                }}
                                handleClick={(e) => {
                                  setActiveStep(1);
                                  setTranscript1(
                                    t(
                                      "ineedaformtoaskmyvisitorstosignuponmywebsite"
                                    )
                                  );
                                  // setFormName(t("contactandregistrationforms"));
                                  setTranscript(
                                    t(
                                      "ineedaformtoaskmyvisitorstosignuponmywebsite"
                                    )
                                  );
                                }}
                              >
                                {t("usethistemplate")}
                                <Copyicon
                                  style={{
                                    fontSize: "1.5rem",
                                    marginLeft: "0.5rem",
                                  }}
                                />
                              </PFButton>
                            </div>
                            {/* <div
                            className='template-image'
                            style={{
                              backgroundColor: "#FFE7AA",
                            }}
                          >
                            <Image
                              src={Template8}
                              alt=''
                              width={175}
                              height={117}
                              fluid
                            />
                          </div> */}
                          </div>
                        </SwiperSlide>
                      </Swiper>
                      <div className="custom-pagination text-center mt-4"></div>
                    </div>
                  </Col>
                </Row>
                {/* <button onClick={getFromFiledData}>asd</button> */}
              </main>
            </Container>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "40vh" }}
            >
              <Spinner />
            </div>
          )}
        </>
      )}

      {formData && (
        <FormPreview
          authorize={authorize}
          data={formData}
          setFormData={setFormData}
          formName={formName}
          setTranscript={setTranscript}
          setTranscript1={setTranscript1}
          setFormName={setFormName}
          setActiveStep={setActiveStep}
          showLinkWithPopup={showLinkWithPopup}
          setShowLinkWithPopup={setShowLinkWithPopup}
          showFormReadyModal={showFormReadyModal}
          setShowFormReadyModal={setShowFormReadyModal}
          link={link}
          setLink={setLink}
        />
      )}

      <PFModal
        isOpen={showChangePlanModal}
        size="large"
        handleClose={() => setShowChangePlanModal(false)}
      >
        <ChangePlanModal
          setPlane1={setPlane}
          setShowChangePlanModal={setShowChangePlanModal}
        />
      </PFModal>
      <PFModal isOpen={showContinuePayment} handleClose={paymaentHandelar}>
        <EnterPaymentDetailsModal />
      </PFModal>
      <PFModal
        isOpen={showNumberOfFormsModal}
        handleClose={() => setShowNumberOfFormsModal(false)}
      >
        <NumberOfFormsModal />
      </PFModal>
      <PFModal
        isOpen={showNumberOfForms25Modal}
        handleClose={() => setShowNumberOfForms25Modal(false)}
      >
        <NumberOfForms25Modal />
      </PFModal>
       <PFModal
        isOpen={tokenOver}
        handleClose={() => setTokenOver(false)}
      >
        <TokenOverModel />
      </PFModal>
    </>
  );
}
