import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Image, Stack } from "react-bootstrap";
import GoogleLogo from "../../assets/Google__G__Logo.svg.webp";
// import { ReactComponent as GoogleLogo} from "../../assets/Google__G__Logo.svg.webp";

import LoderGif from "../../assets/images/Lodder.gif";
import { PFButton } from "../PFButton";

const TokenOverModel = ({
  callApiTypeform,
  showLoader,
  generateGoogleForm,
  googleOauthlogin,
  setShowLinkWithPopup,
  authorize,
  isLodding,
}) => {
  const { t } = useTranslation("common");
  const handleClose = () => {
    setShowLinkWithPopup(false);
  };

  return (
    <>
    
        <div className="d-flex justify-content-center align-items-center mt-2 mb-5 ">
          
          
         
           <h1
                className="p dark  text-justify fw-bold mt-2"
                style={{ fontSize: "25px", lineHeight: "35px" }}
              >
                {t("Youreachedyourlimitoftokensforformsgeneration.")}<a href="mailto:hello@processtalks.com" className="p fw-bold mt-2"
                style={{ fontSize: "25px", lineHeight: "35px",cursor:"pointer",textDecoration:'none' }}>
                  hello@processtalks.com  </a>
                  {t("toknowhowtogetnewtokens.")}
              </h1>  
        
        </div>
    
    
     

      
    </>
  );
};

export default TokenOverModel
