import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { toast } from "react-hot-toast";
import { db } from "../firebase";
//stripe test  key
// export const client_secret =
//   "sk_test_51I8lK7E47A5wkRHrRQeFURU2JAEAEayjawW4852YDrArhOTuDR6v9FMhFSTLqFPc5k8ClpUe39lGsY2fz362v4VM002MLqbU6d";
export const client_secret =
  "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";

//Typeform key
// export const typeFormclientId = "CCPp5V9EoFNQFFsRokQ3AqVGc8VgaSJw3k1t5wM4z26P";
// export const typeFormclientSecret =
//   "Grtn3sP59kdV4vUVenLuJAxk18PVAZRUevPVYpLnzNwa";
export const typeFormclientId = "ByRJ8ivwXVLqHczXrMerhaGEDZs4XaJhVedg1csBVUCa";

export const typeFormclientSecret =
  "4J6rxoDLWbokjYe7vRAvjki6RKkf9WDsxb4umabw6fXT";

export const firebaseAddUrl = (link) => {
  const user = JSON.parse(window.localStorage.getItem("USER"));
  // Add a new document with a generated id.
  const docRef = doc(db, "users", user.uid);
  const colRef = collection(docRef, "links");
  addDoc(colRef, {
    link: link,
    timestamp: serverTimestamp(),
  });
};
export const paymentDeteli = (data) => {
  const user = JSON.parse(window.localStorage.getItem("USER"));
  // Add a new document with a generated id.
  const docRef = doc(db, "users", user.uid);
  const colRef = collection(docRef, "PaymentDateli");

  addDoc(colRef, {
    data: data,
    uid: user.uid,
    Date: new Date().toJSON().slice(0, 10),
    timestamp: serverTimestamp(),
  });
};
export const updateCustomerBillAddress = (
  cusId,
  cardId,
  data,
  setShowLoader,
  t
) => {
  const { name, street, country, postal, city, VAT } = data;

  var urlencoded = new URLSearchParams();

  urlencoded.append("name", name);
  urlencoded.append("address_city", city);
  urlencoded.append("address_country", country);
  urlencoded.append("address_line1", street);
  // urlencoded.append('address_line2', '');
  urlencoded.append("address_zip", postal);
  urlencoded.append("metadata[VAT]", VAT);

  // urlencoded.append('address[state]', data?.region);
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${client_secret}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };
  fetch(
    `https://api.stripe.com/v1/customers/${cusId}/sources/${cardId}`,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result) {
        toast.success(`${t("successfully update billingDetail")}`);
        setShowLoader(false);
      } else {
        setShowLoader(false);
        toast.error(`${t("something went wrong please try again")}`);
      }
    })
    .catch((error) => console.log("error", error));
};
export const billingDetail = async (
  data,
  setShowLoader,
  customerId,
  cardId,
  t
) => {
  const user = JSON.parse(window.localStorage.getItem("USER"));
  const { name, street, country, postal, city, VAT } = data;
  // Add a new document with a generated id.
  const docRef = doc(db, "users", user.uid, "BillingDetail", "Information");
  try {
    await setDoc(docRef, {
      name,
      street,
      country,
      postal,
      city,
      VAT,
    })
      .then(() => {
        updateCustomerBillAddress(customerId, cardId, data, setShowLoader, t);
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
  } catch (e) {
    console.log(e);
  }
};

export const customerAddFirebase = async (cusiId, cardId, t) => {
  const user = JSON.parse(window.localStorage.getItem("USER"));

  try {
    const colRef = doc(db, `users/${user.uid}/FreeTrialYAMMY/formNumberCreate`);

    await updateDoc(colRef, {
      customerId: cusiId,
      cardId: cardId,
    })
      .then(() => {
        toast.success(`${t("Successfully added payment details")}`);
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
  }
};

export const createCard = (data, setIsLodding, t, setData) => {
  const user = JSON.parse(window.localStorage.getItem("USER"));
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${client_secret}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("card[number]", data.number);
  urlencoded.append("card[exp_month]", data.mm);
  urlencoded.append("card[exp_year]", data.yy);
  urlencoded.append("card[cvc]", data.cvv);
  urlencoded.append("card[name]", data?.name ?? user.displayName);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  fetch("https://api.stripe.com/v1/tokens", requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result) {
        createCustomer(result.id, result.card.id, data, setIsLodding, t);
      } else {
        setIsLodding(false);
        setData(false);
        toast.error(`${t("something went wrong please try again")}`);
      }
    })
    .catch((error) => console.log("error", error));
};

export const createCustomer = (tokenid, cardid, data, setIsLodding, t) => {
  const user = JSON.parse(window.localStorage.getItem("USER"));
  var urlencoded = new URLSearchParams();
  urlencoded.append("description", `${user.email}`);
  urlencoded.append("email", `${user.email}`);
  urlencoded.append("name", data?.name ?? `${user.displayName}`);
  urlencoded.append("address[city]", data?.city);
  urlencoded.append("address[country]", data?.country);
  urlencoded.append("address[line1]", data?.address);
  urlencoded.append("address[line2]", data?.Apt);
  urlencoded.append("address[postal_code]", data?.postalcode);
  urlencoded.append("address[state]", data?.region);

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${client_secret}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  fetch("https://api.stripe.com/v1/customers", requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result) {
        updateCustomer(result.id, tokenid, cardid, setIsLodding, t);
      } else {
        toast.error(`${t("something went wrong please try again")}`);
        setIsLodding(false);
      }
    })
    .catch((error) => console.log("error", error));
};

export const updateCustomer = (cusId, tokenId, cardid, setIsLodding, t) => {
  // let Customerdata = new FormData();
  var urlencoded = new URLSearchParams();
  urlencoded.append("source", tokenId);
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${client_secret}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };
  fetch(`https://api.stripe.com/v1/customers/${cusId}`, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result) {
        customerAddFirebase(result.id, cardid, t);

        setIsLodding(false);
      } else {
        toast.error(`${t("something went wrong please try again")}`);
        setIsLodding(false);
      }
    })
    .catch((error) => console.log("error", error));
};

export const updatePlaneStatus = async (t) => {
  const user = JSON.parse(window.localStorage.getItem("USER"));

  try {
    const colRef = doc(db, `users/${user.uid}/FreeTrialYAMMY/formNumberCreate`);

    await updateDoc(colRef, {
      Current_form: 0,
      status: "deactived",
      plane: "",
      UserId: "",
      paidPlan: false,
      freeform: 5,
      startDate: 0,
      endDate: 0,
      totalForm: 0,
      planType: "",
      isPayment: true,
      link: [],
    })
      .then(() => {
        toast.success(`${t("payment successfully")}`);
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
  }
};
export const monthsCharge = (
  amount,
  customerId,
  planType,
  setShowCancelSuccess,
  setShowCancelPlanModal,
  t,
  setcanclePlaneLoder
) => {
  var urlencoded = new URLSearchParams();

  urlencoded.append("amount", amount);
  urlencoded.append("currency", "eur");
  urlencoded.append("customer", customerId);
  urlencoded.append("metadata[PlanType]", planType);

  urlencoded.append(
    "description",
    "My First Test Charge (created for API docs at https://www.stripe.com/docs/api)"
  );
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${client_secret}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  fetch("https://api.stripe.com/v1/charges", requestOptions)
    .then((response) => {
      if (!response.ok) {
        setShowCancelPlanModal(false);
        toast.error(`${t("payment failed,Please try again")}`);
        throw new Error("Network response was not OK");
      }
      return response.json();
    })
    .then((result) => {
      if (result) {
        setShowCancelPlanModal(false);
        setShowCancelSuccess(true);
        paymentDeteli(result);
        updatePlaneStatus(t);
        setcanclePlaneLoder(false);
      } else {
        toast.error(`${t("payment failed,Please try again")}`);
        setShowCancelPlanModal(false);
        setcanclePlaneLoder(false);
      }
    })
    .catch((error) => {
      setShowCancelPlanModal(false);
      setcanclePlaneLoder(false);
      console.log("error", error);
    });
};
