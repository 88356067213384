import "bootstrap/dist/css/bootstrap.min.css";
// import "@/fonts/fonts.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_sp from "./translations/sp/common.json";
import common_en from "./translations/en/common.json";
import common_ca from "./translations/ca/common.json";
import "./index.css";
const lan = localStorage.getItem("language");
i18next.init({
  fallbackLng: "en",
  interpolation: { escapeValue: false }, // React already does escaping
  lng: !lan ? "sp" : !lan ? "ca" : lan, // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    sp: {
      common: common_sp,
    },
    ca: {
      common: common_ca,
    },
  },
});
ReactDOM.createRoot(document.getElementById("root")).render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);
