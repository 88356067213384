import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { client_id, client_secret } from "../../API/GoogleOauthApi";
import { PFButton, PFInput, PFModal, SVGIcons } from "../../components";
import { PFCheckBox } from "../../components";
import { db } from "../../firebase";
import "./FormPreview.css";
import ChangePlanModal from "../../components/model/ChangePlanModal";
import EnterPaymentDetailsModal from "../../components/model/EnterPaymentDetailsModal";
import {
  firebaseAddUrl,
} from "../../stripe/api";
import { useTranslation } from "react-i18next";
import { ReactComponent as Emojicon } from "../../assets/images/icons/emoji.svg";
import { ReactComponent as MultipleChoice } from "../../assets/images/multiple-option.svg";
import { ReactComponent as Calendar } from "../../assets/images/calendar.svg";
import { ReactComponent as Clock } from "../../assets/images/clock.svg";
import { ReactComponent as Pragraph } from "../../assets/images/pragraph.svg";
import { ReactComponent as Checkbox } from "../../assets/images/verification.svg";
import InputType from "./InputType";
import LoderGif from "../../assets/images/Lodder.gif";
import LinkWithGoogleModal from "../../components/model/LinkWithGoogleModal";

import { useGoogleLogin } from "@react-oauth/google";


function FormPreview({
  authorize,
  data,
  setFormData,
  formName,
  setTranscript,
  setTranscript1,
  setFormName,
  setActiveStep,
  showLinkWithPopup,
  setShowLinkWithPopup,
  showFormReadyModal,
  setShowFormReadyModal,
  link,
  setLink,
}) {
  const { t } = useTranslation("common");
  const [showRestartModal, setShowRestartModal] = useState(false);
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [showContinuePayment, setShowContinuePayment] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showpopup, setShowPopup] = useState(false);
  const [startButtonLoder, setStartButtonLoder] = useState(false);
  const [isLodding, setIsLodding] = useState(false);
  const [googleLinkButton, setGoogleButton] = useState(false);
  localStorage.setItem("API_Data", JSON.stringify(data));

  const PlateForm = localStorage.getItem("PlateForm");
  

  const googleOauthlogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (tokenResponse) => {
      getRefreshToken(tokenResponse.code);
    },

    onError: () => {
      console.log("Login Failed");
    },
    scope: "https://www.googleapis.com/auth/forms.body ",
  });

  const getRefreshToken = (code) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("code", code);
    urlencoded.append("client_id", client_id);
    urlencoded.append("client_secret", client_secret);
    urlencoded.append("redirect_uri", window.location.origin);
    // urlencoded.append("redirect_uri", "http://localhost:3000");

    urlencoded.append("grant_type", "authorization_code");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://oauth2.googleapis.com/token", requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
        }
        // Handle the response
      })
      .then(async (val) => {
        generateForm();
        addToken(val.access_token, val.refresh_token);
        localStorage.setItem("accessToken", val.access_token);
        localStorage.setItem("refreshToken", val.refresh_token);
        // yummyplane();
      })
      .catch((error) => {
        // Handle the error
        console.log(error);
      });
  };
  const addToken = async (access_token, refresh_token) => {
    const user = JSON.parse(window.localStorage.getItem("USER"));
    // Add a new document with a generated id.
    const docRef = doc(db, "users", user.uid, "GoogleLinkToken", "token");
    setDoc(docRef, {
      access_token: access_token,
      refresh_token,
      PlateForm: "GoogleForm",
    }).catch((e) => {
      console.log(e);
    });
  };

  const handleHidepopup = (e) => {
    const { checked } = e.target;
    if (checked) {
      localStorage.setItem("hidepopup", true);
    }
  };

  const shouldShowPopup = !localStorage.getItem("hidepopup");

  const StartAgainModal = () => {
    const startAgain = () => {
      setShowRestartModal(false);
      setFormData("");
      setTranscript("");
      setTranscript1("");
      setFormName("");
      setActiveStep(0);
    };
    return (
      <>
        <div className="mb-4">
          <h6 className="p fw-bold text-black">
            {t("Areyousureyouwanttostartagain?")}
          </h6>
          <p className="p-sm mb-0 text-black">
            {t("Startingoverwill")} <b>{t("spendanotherform")}</b>
          </p>
        </div>

        <PFCheckBox
          id={"dontShow"}
          label={t("Don'tshowmeagain")}
          parentClassName="mb-3"
          handleChange={handleHidepopup}
        />

        <div className="d-flex gap-3 mt-4">
          <PFButton
            style={{ fontSize: "1rem", fontWeight: 400 }}
            variant={"dark"}
            size="small"
            className={"fw-normal"}
            // onClick={handleHidepopup}
            handleClick={startAgain}
          >
            {t("startagain")}
          </PFButton>
          <PFButton
            variant={"dark-bordered"}
            size="small"
            className={"fw-normal"}
            handleClick={() => setShowPopup(false)}
          >
            {t("cancel")}
          </PFButton>
        </div>
      </>
    );
  };
  const FormReadyModal = () => {
    const [buttonTextCopy, setButtonTextCopy] = useState(false);
    const openForm = () => {
      window.open(link, "_blank");
      // setShowFormReadyModal(false);
      // setFormData("");
    };
    return (
      <>
        <div className="mb-4">
          <h2 className="fw-bold dark-blue">
            Voilà!{" "}
            <span className="h1">
              <Emojicon widths="45px" height="48px" />
            </span>
          </h2>
        </div>

        <div className="mb-4">
          <h3 className="h2 mb-1 dark-blue">{t("¡Yourformisready!")}</h3>
          {PlateForm === "Typeform" ? (
            <p className="h6 dark-blue">{t("Usethislink1")}</p>
          ) : (
            <p className="h6 dark-blue">{t("Usethislink")}</p>
          )}
        </div>

        <div className="d-flex gap-2">
          <PFInput
            size={"small"}
            parentClassName={"w-100 fw-normal"}
            // value={`${link}`}
            placeholder={`${link}`}
            // defaultValue={`${link}`}
          />

          <PFButton
            disabled={buttonTextCopy}
            size={"small"}
            variant={"primary"}
            className="flex-shrink-0 gap-3"
            onClick={() => {
              navigator.clipboard
                .writeText(link)
                .then(() => {
                  setButtonTextCopy(true);
                })
                .catch((error) => {
                  setButtonTextCopy(false);
                });
            }}
            style={{
              backgroundColor: "#FBE54D",
              border: "1px solid #B4B4B4",
              padding: "8px 14px",
              fontWeight: "400",
            }}
          >
            {SVGIcons.Copy} {t("Copy")}
          </PFButton>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <PFButton
            variant={"secondary"}
            size="small"
            className={"fw-normal"}
            handleClick={openForm}
          >
            {t("Openform")}
          </PFButton>
        </div>
      </>
    );
  };
  const removeElement = (id) => {
    const newFruits = data.filter((fruit) => fruit.name !== id);
    setFormData(newFruits);
  };

  const generateForm = (t) => {
    setShowLoader(true);
    localStorage.setItem("PlateForm","GoogleForm")
    var accessToken = localStorage.getItem("accessToken");
    fetch("https://forms.googleapis.com/v1/forms", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify({
        info: {
          title: localStorage.getItem("fromName"),
          document_title: localStorage.getItem("fromName"),
        },
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status == 401) {
            refreshToken();
            // toast.error(`${t("Please try again")}`);
          }
          throw console.error("");

          // setShowLoader(false)
        }
        // Handle the response
      })
      .then((response) => {
        featchDataFromApi(response.formId, data, response.responderUri);
      })
      .catch((error) => {
        console.log(error);
        // setShowLoader(false);
      });
  };
  const refreshToken = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("client_id", client_id);
    urlencoded.append("client_secret", client_secret);
    urlencoded.append("refresh_token", localStorage.getItem("refreshToken"));
    urlencoded.append("grant_type", "refresh_token");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://oauth2.googleapis.com/token", requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((result) => {
        localStorage.setItem("accessToken", result.access_token);
        const user = JSON.parse(window.localStorage.getItem("USER"));
        const colRef = doc(db, `users/${user.uid}/GoogleLinkToken/token`);
        updateDoc(colRef, {
          access_token: result.access_token,
        })
          .then((docRef) => {
            generateForm();
            // setShowLoader(false);
            // toast.success(`${t("token renewal successfully")}`);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => console.log("error", error));
  };
  const featchDataFromApi = (formId, data, url) => {
    let arr = [];

    arr.push({
      updateFormInfo: {
        info: {
          title: localStorage.getItem("fromName"),
          description: `This form has been generated with https://peasyforms.com`,
        },
        updateMask: "*",
      },
    });
    data.forEach((element, index) => {
      if (element.type === "text") {
        arr.push({
          createItem: {
            item: {
              title: element.label,
              questionItem: {
                question: {
                  textQuestion: {
                    paragraph: false,
                  },
                  required: element.required,
                },
              },
            },
            location: {
              index: index,
            },
          },
        });
      } else if (element.type === "text_area") {
        arr.push({
          createItem: {
            item: {
              title: element.label,
              questionItem: {
                question: {
                  textQuestion: {
                    paragraph: true,
                  },
                  required: element.required,
                },
              },
            },
            location: {
              index: index,
            },
          },
        });
      } else if (element.type === "number") {
        arr.push({
          createItem: {
            item: {
              title: element.label,
              questionItem: {
                question: {
                  textQuestion: {
                    paragraph: false,
                  },
                  required: element.required,
                },
              },
            },
            location: {
              index: index,
            },
          },
        });
      } else if (element.type === "time") {
        arr.push({
          createItem: {
            item: {
              title: element.label,
              questionItem: {
                question: {
                  timeQuestion: {
                    duration: true,
                  },
                  required: element.required,
                },
              },
            },
            location: {
              index: index,
            },
          },
        });
      } else if (element.type === "password") {
        arr.push({
          createItem: {
            item: {
              title: element.label,
              questionItem: {
                question: {
                  textQuestion: {
                    paragraph: false,
                  },
                  required: element.required,
                },
              },
            },
            location: {
              index: index,
            },
          },
        });
      } else if (element.type === "date") {
        arr.push({
          createItem: {
            item: {
              title: element.label,
              questionItem: {
                question: {
                  dateQuestion: {
                    includeYear: true,
                  },
                  required: element.required,
                },
              },
            },
            location: {
              index: index,
            },
          },
        });
      } else if (element.type === "choice") {
        const array = [];
        element.options.forEach((i) => {
          array.push({
            value: i,
          });
        });
        arr.push({
          createItem: {
            item: {
              title: element.label,
              questionItem: {
                question: {
                  choiceQuestion: {
                    type: "RADIO",
                    options: array,
                  },
                  required: element.required,
                },
              },
            },
            location: {
              index: index,
            },
          },
        });
      } else if (element.type === "multiple_choice") {
        const array = [];
        element.options.forEach((i) => {
          array.push({
            value: i,
          });
        });
        arr.push({
          createItem: {
            item: {
              title: element.label,
              questionItem: {
                question: {
                  choiceQuestion: {
                    type: "CHECKBOX",
                    options: array,
                  },
                  required: element.required,
                },
              },
            },
            location: {
              index: index,
            },
          },
        });
      } else if (element.type === "checkbox") {
        const array = [];
        if (element.options) {
          element.options.forEach((i) => {
            array.push({
              value: i,
            });
          });
        }
        arr.push({
          createItem: {
            item: {
              title: element.label,
              questionItem: {
                question: {
                  choiceQuestion: {
                    type: "CHECKBOX",
                    options: element.options
                      ? array
                      : [
                          {
                            value: "Yes",
                          },
                          {
                            value: "NO",
                          },
                        ],
                  },
                  required: element.required,
                },
              },
            },
            location: {
              index: index,
            },
          },
        });
      }
    });

    const formData = {
      requests: arr,
    };

    upDateForm(formId, formData, url);
  };
  const perDaygeneratedTypeFrom = async () => {
    const docRef = doc(db, "formData", "everyDayEmailData");
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, {
          perDatTotalTypeFrom: docSnap.data().perDatTotalTypeFrom + 1,
          TotalTypeFrom: docSnap.data().TotalTypeFrom + 1,
        });
      } else {
        setDoc(docRef, {
          perDatTotalTypeFrom: 1,
          TotalTypeFrom: 1,
          newUserTotalTypeFrom: 1,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const newUserCreateTypeFrom = async () => {
    const user = JSON.parse(window.localStorage.getItem("USER"));
    const docRef = doc(db, "newUser", "newUserData");

    const newUserGet = await getDoc(docRef);
    if (newUserGet.exists) {
      try {
        const userId = newUserGet.data().uid;
        const foundUser = userId.find((element) => element === user.uid);
        if (foundUser) {
          const docRef = doc(db, "formData", "everyDayEmailData");

          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            await updateDoc(docRef, {
              newUserTotalTypeFrom: docSnap.data().newUserTypeFrom + 1,
            });
          } else {
            setDoc(docRef, {
              newUserTotalTypeFrom: 1,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const perDaygeneratedGoolgeFrom = async () => {
    const docRef = doc(db, "formData", "everyDayEmailData");
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, {
          perDayGoogleTotalFrom: docSnap.data().perDayGoogleTotalFrom + 1,
          TotalGoogleForms: docSnap.data().TotalGoogleForms + 1,
        });
      } else {
        setDoc(docRef, {
          perDayGoogleTotalFrom: 1,
          TotalGoogleForms: 1,
          newUserTotalGoogleform: 1,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const newUserCreateGoolgeForm = async () => {
    const user = JSON.parse(window.localStorage.getItem("USER"));
    const docRef = doc(db, "newUser", "newUserData");

    const newUserGet = await getDoc(docRef);
    if (newUserGet.exists) {
      try {
        const userId = newUserGet.data().uid;
        const foundUser = userId.find((element) => element === user.uid);
        if (foundUser) {
          const docRef = doc(db, "formData", "everyDayEmailData");

          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            await updateDoc(docRef, {
              newUserTotalGoogleform: docSnap.data().newUserTotalGoogleform + 1,
            });
          } else {
            setDoc(docRef, {
              newUserTotalGoogleform: 1,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const upDateForm = (formId, formData, url) => {
    var accessToken = localStorage.getItem("accessToken");

    fetch(
      `https://forms.googleapis.com/v1/forms/${formId}:batchUpdate?key=AIzaSyDZXZoO_N-bZCkukbWRDepF6SdA_Z7hxrg`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
        }
        // Handle the response
      })
      .then((val) => {
        firebaseAddUrl(url);
        perDaygeneratedGoolgeFrom();
        newUserCreateGoolgeForm();
        setLink(url);
        setShowLoader(false);
        setShowFormReadyModal(true);
        setShowLinkWithPopup(false);
        setActiveStep(3);
      })
      .catch((error) => {
        setShowLoader(false);
        // Handle the error
        console.log(error);
      });
  };

  const paymaentHandelar = () => {
    setShowContinuePayment(false);
    setShowLoader(false);
  };

  const CreteTypeForm = (typeformdata) => {
    setIsLodding(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "accessToken",
      `Bearer ${localStorage.getItem("TypeForm_accessToken")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: typeformdata,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://europe-west3-process-talks-forms.cloudfunctions.net/createTypeForm",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((result) => {
        if (result) {
          setLink(result?.data?._links?.display);
          setShowFormReadyModal(true);
          setActiveStep(3);
          setShowLinkWithPopup(false);
          newUserCreateTypeFrom();
          perDaygeneratedTypeFrom();

          setIsLodding(false);
        } else {
          setIsLodding(false);
        }
      })
      .catch((error) => {
        setIsLodding(false);
        console.log("error", error);
      });
  };
  const callApiTypeform = () => {
    setIsLodding(false);

    let arr = [];
    data.forEach((element, index) => {
      if (element.type === "text") {
        arr.push({
          title: element.label,
          type: "short_text",
          validations: {
            // max_length: 50,
            required: element.required ? true : false,
          },
        });
      } else if (element.type === "text_area") {
        arr.push({
          title: element.label,
          type: "long_text",
          validations: {
            required: element.required ? true : false,
          },
        });
      } else if (element.type === "number") {
        arr.push({
          title: element.label,
          type: "number",
          validations: {
            required: element.required ? true : false,
          },
        });
      }
      // 3aqsVSEqxi1jQTYSk8LJRXhRFonEvX4C673ZYWPvpKHX
      //  else if (element.type === "file") {
      //   arr.push({
      //     title: "Upload File",
      //     type: "file_upload",
      //     ref: "fileUploadField",
      //     properties: {
      //       description: "Please upload your file",
      //     },
      //   });
      // }
      else if (element.type === "time") {
        arr.push({
          title: element.label,
          type: "short_text",
          validations: {
            // max_length: 50,
            required: element.required ? true : false,
          },
        });
      } else if (element.type === "password") {
        arr.push({
          title: element.label,
          type: "short_text",
          validations: {
            // max_length: 50,
            required: element.required ? true : false,
          },
        });
      } else if (element.type === "date") {
        arr.push({
          properties: {
            separator: "-",
            structure: "DDMMYYYY",
          },
          title: element.label,
          type: "date",
          validations: {
            required: element.required ? true : false,
          },
        });
      } else if (element.type === "choice") {
        const array = [];
        element.options.forEach((i) => {
          array.push({
            label: i,
          });
        });
        arr.push({
          properties: {
            // alphabetical_order: false,
            choices: array,
            // description: "Cool description for the dropdown",
            // randomize: false,
          },
          // ref: "nice_readable_dropdown_reference",
          title: element.label,
          type: "dropdown",
          validations: { required: element.required ? true : false },
        });
      } else if (element.type === "multiple_choice") {
        const array = [];
        element.options.forEach((i) => {
          array.push({
            label: i,
          });
        });
        arr.push({
          properties: {
            allow_multiple_selection: true,
            allow_other_choice: true,
            choices: array,
            randomize: true,
            vertical_alignment: false,
          },
          title: element.label,
          type: "multiple_choice",
          validations: {
            required: element.required ? true : false,
          },
        });
      } else if (element.type === "checkbox") {
        const array = [];
        element.options.forEach((i) => {
          array.push({
            label: i,
          });
        });
        arr.push({
          properties: {
            allow_multiple_selection: true,
            allow_other_choice: true,
            choices: array,
            randomize: true,
            vertical_alignment: false,
          },
          title: element.label,
          type: "multiple_choice",
          validations: {
            required: element.required ? true : false,
          },
        });
      }
    });

    var formData = {
      title: localStorage.getItem("fromName"),
      fields: arr,
      thankyou_screens: [
        {
          // attachment: {
          //   href: ,
          //   type: "image",
          // },
          properties: {
            button_mode: "redirect",
            button_text: "Go to PeasyFoms",
            redirect_url: "https://peasyforms.com/",
            share_icons: false,
            show_button: true,
          },
          type: "thankyou_screen",
          ref: "nice-readable-thank-you-ref",
          title: "Thank you ",
        },
      ],
    };

    CreteTypeForm(formData);
  };

  const generateFormNow = () => {
    setShowLinkWithPopup(true);
  };

  return (
    <>
      <Container>
        <main className="py-4">
          <div className="mb-4">
            <h5 className="fw-bold dark-blue">{t("thiswillbeyourform")}</h5>
            <p className="mb-0">
              {t("reviewallthefieldsandgenerateyourformwithaclick")}
            </p>
          </div>

          {/* <Row> */}
          {/* <Col md={6}> */}
          <Row xs={1} md={1} lg={2}>
            {data?.map((item, index) => (
              <div className="form-control-preview" key={index}>
                <Row xs={2} md={2}>
                  <Col xs={9} md={10} lg={10}>
                    <Card
                      className="previewCard"
                      style={{
                        width: "100%",
                        backgroundColor: "#E6E6E6",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          margin: 7,
                          marginLeft: "1rem",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            padding: 1,
                          }}
                        >
                          {item.label}
                        </p>
                      </div>
                    </Card>
                  </Col>

                  <Col xs={3} md={2} lg={2}>
                    <button
                      className="remove-form-control"
                      onClick={() => removeElement(item.name)}
                    >
                      {SVGIcons.Dustbin}
                    </button>
                  </Col>
                </Row>
                {item.type === "text" && (
                  <InputType
                    typeIcon={<Pragraph />}
                    text={"Paragraph"}
                    motivation={item.motivation}
                  />
                )}
                {item.type === "text_area" && (
                  <InputType
                    typeIcon={<Pragraph />}
                    text={"Paragraph"}
                    motivation={item.motivation}
                  />
                )}
                {item.type === "password" && (
                  <InputType
                    typeIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-file-lock-fill font-weight-bold"
                        viewBox="0 0 16 16"
                        color="#4F4F4F"
                      >
                        <path d="M7 6a1 1 0 0 1 2 0v1H7V6zM6 8.3c0-.042.02-.107.105-.175A.637.637 0 0 1 6.5 8h3a.64.64 0 0 1 .395.125c.085.068.105.133.105.175v2.4c0 .042-.02.107-.105.175A.637.637 0 0 1 9.5 11h-3a.637.637 0 0 1-.395-.125C6.02 10.807 6 10.742 6 10.7V8.3z" />
                        <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 6v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V8.3c0-.627.46-1.058 1-1.224V6a2 2 0 1 1 4 0z" />
                      </svg>
                    }
                    text={"Password"}
                    motivation={item.motivation}
                  />
                )}
                {item.type === "date" && (
                  <InputType
                    typeIcon={<Calendar />}
                    text={"Date"}
                    motivation={item.motivation}
                  />
                )}

                {item.type === "time" && (
                  <InputType
                    typeIcon={<Clock />}
                    text={"Time"}
                    motivation={item.motivation}
                  />
                )}
                {item.type === "number" && (
                  <InputType
                    typeIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-123 font-weight-bold"
                        viewBox="0 0 16 16"
                        color="#4F4F4F"
                      >
                        <path d="M2.873 11.297V4.142H1.699L0 5.379v1.137l1.64-1.18h.06v5.961h1.174Zm3.213-5.09v-.063c0-.618.44-1.169 1.196-1.169.676 0 1.174.44 1.174 1.106 0 .624-.42 1.101-.807 1.526L4.99 10.553v.744h4.78v-.99H6.643v-.069L8.41 8.252c.65-.724 1.237-1.332 1.237-2.27C9.646 4.849 8.723 4 7.308 4c-1.573 0-2.36 1.064-2.36 2.15v.057h1.138Zm6.559 1.883h.786c.823 0 1.374.481 1.379 1.179.01.707-.55 1.216-1.421 1.21-.77-.005-1.326-.419-1.379-.953h-1.095c.042 1.053.938 1.918 2.464 1.918 1.478 0 2.642-.839 2.62-2.144-.02-1.143-.922-1.651-1.551-1.714v-.063c.535-.09 1.347-.66 1.326-1.678-.026-1.053-.933-1.855-2.359-1.845-1.5.005-2.317.88-2.348 1.898h1.116c.032-.498.498-.944 1.206-.944.703 0 1.206.435 1.206 1.07.005.64-.504 1.106-1.2 1.106h-.75v.96Z" />
                      </svg>
                    }
                    text={"Number"}
                    motivation={item.motivation}
                  />
                )}
                {item.type === "choice" && (
                  <InputType
                    typeIcon={<MultipleChoice width="16" height="16" />}
                    text={"Single choice"}
                    data={item.options}
                    motivation={item.motivation}
                  />
                )}
                {item.type === "multiple_choice" && (
                  <InputType
                    typeIcon={<Checkbox />}
                    text={"Checkboxes"}
                    data={item.options}
                  />
                )}
                {item.type === "checkbox" && (
                  <InputType
                    typeIcon={<MultipleChoice />}
                    text={"Multiple choice"}
                    data={item.options}
                    type="checkbox"
                    motivation={item.motivation}
                  />
                )}
              </div>
            ))}
          </Row>
          {/* </Col> */}

          <div className="generate-btn-wrap">
            <Row className="mt-3">
              <Col xs={6} className="d-flex justify-content-end">
                <div>
                  <PFButton
                    className="buttonhover"
                    style={{
                      fontSize: "1.125rem",
                      fontWeight: "800",
                      borderRadius: 8,
                      border: "none",
                    }}
                    handleClick={generateFormNow}
                  >
                    {t("generateform")}
                  </PFButton>
                  {/* asd */}
                </div>
              </Col>
              <Col xs={6}>
                <PFButton
                  className="start-button"
                  style={{
                    fontSize: "1rem",
                    fontWeight: 400,
                  }}
                  handleClick={() => {
                    if (shouldShowPopup) {
                      setShowPopup(true);
                    } else {
                      setStartButtonLoder(true);
                      setTimeout(() => {
                        setStartButtonLoder(false);
                        setShowPopup(false);
                        setFormData("");
                        setTranscript("");
                        setTranscript1("");
                        setFormName("");
                        setActiveStep(0);
                      }, 3000);
                    }
                  }}
                >
                  {t("startagain")}
                  {startButtonLoder && (
                    <span style={{ marginLeft: "12px" }}>
                      <img
                        alt="gitloder"
                        width="25px"
                        height="25px"
                        color="red"
                        src={LoderGif}
                      />
                    </span>
                  )}
                </PFButton>
              </Col>
            </Row>
          </div>
          {/* </Row> */}
        </main>
      </Container>
      <PFModal isOpen={showpopup} handleClose={() => setShowPopup(false)}>
        <StartAgainModal />
      </PFModal>

      <PFModal
        isOpen={showFormReadyModal}
        handleClose={() => setShowFormReadyModal(false)}
      >
        <FormReadyModal />
      </PFModal>

      <PFModal
        isOpen={showChangePlanModal}
        size="large"
        handleClose={() => setShowChangePlanModal(false)}
      >
        <ChangePlanModal setShowChangePlanModal={setShowChangePlanModal} />
      </PFModal>
      <PFModal isOpen={showContinuePayment} handleClose={paymaentHandelar}>
        <EnterPaymentDetailsModal />
      </PFModal>
      <PFModal
        isOpen={showLinkWithPopup}
        handleClose={() => setShowLinkWithPopup(false)}
      >
        <LinkWithGoogleModal
          isLodding={isLodding}
          callApiTypeform={callApiTypeform}
          googleOauthlogin={googleOauthlogin}
          setShowLinkWithPopup={setShowLinkWithPopup}
          authorize={authorize}
          googleLinkButton={googleLinkButton}
          showLoader={showLoader}
          generateGoogleForm={generateForm}
        />
      </PFModal>
    </>
  );
}
export default FormPreview;
