import React from "react";
import { ReactComponent as ActiveImage } from "../../assets/images/stepperActive.svg";
import { ReactComponent as Stepperimage } from "../../assets/images/stepperimage.svg";
import { ReactComponent as Disbaleimage } from "../../assets/images/disbleStapper.svg";

import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const HorizontalStepper = ({ currentStep }) => {
  const { t } = useTranslation("common");

  return (
    <Container>
      <div className='horizontal-stepper'>
        <div>
          <div>
            {currentStep > 1 ? (
              <ActiveImage />
            ) : currentStep === 0 ? (
              <Disbaleimage />
            ) : (
              <Stepperimage />
            )}
          </div>
        </div>
        <hr
          style={{
            width: "100%",
            height: "2px",
            borderWidth: "0",
            color: "gray",
            opacity: "1",
            backgroundColor: "#221047",
          }}
        ></hr>

        <div>
          <div>
            {currentStep > 2 ? (
              <ActiveImage />
            ) : currentStep > 1 ? (
              <Stepperimage />
            ) : (
              <Disbaleimage />
            )}
          </div>
        </div>
        <hr
          style={{
            width: "100%",
            height: "2px",
            borderWidth: "0",
            color: "gray",
            backgroundColor: "#221047",
            opacity: "1",
          }}
        ></hr>
        <div>
          <div>{currentStep > 2 ? <ActiveImage /> : <Disbaleimage />}</div>
        </div>
      </div>
      <div className='horizontal-stepper-text '>
        <div>
          <p
            style={{
              fontWeight: `${currentStep > 0 ? "bold" : ""}`,
              color: `${currentStep > 0 ? "#351771" : "#4F4F4F"}`,
            }}
          >
            {t("write1")}
          </p>
        </div>

        <div>
          <p
            style={{
              fontWeight: `${currentStep > 1 ? "bold" : ""}`,
              color: `${currentStep > 1 ? "#351771" : "#4F4F4F"}`,
            }}
          >
            {t("review")}
          </p>
        </div>

        <div>
          <p
            style={{
              fontWeight: `${currentStep > 2 ? "bold" : ""}`,
              color: `${currentStep > 2 ? "#351771" : "#4F4F4F"}`,
            }}
          >
            {t("generate")}
          </p>{" "}
        </div>
      </div>
    </Container>
  );
};

export default HorizontalStepper;
