import { initializeApp } from "firebase/app";
import axios from "axios";

import {
  getAdditionalUserInfo,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDZXZoO_N-bZCkukbWRDepF6SdA_Z7hxrg",
  authDomain: "process-talks-forms.firebaseapp.com",
  databaseURL:
    "https://process-talks-forms-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "process-talks-forms",
  storageBucket: "process-talks-forms.appspot.com",
  messagingSenderId: "796758071306",
  appId: "1:796758071306:web:9b60ca19f7dcffe8ac27f3",
  measurementId: "G-37PGNGDMKS",
};
// Initialize Firebase
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// };

const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();
export const auth = getAuth(app);
export const db = getFirestore(app);
const yummyplane = () => {
  const user = JSON.parse(window.localStorage.getItem("USER"));
  const cuurentdate = new Date();
  const date = new Date();
  date.setDate(date.getDate() + 90);
  const docRef = doc(
    db,
    "users",
    user.uid,
    "FreeTrialYAMMY",
    "formNumberCreate"
  );
  setDoc(docRef, {
    Current_form: 0,
    status: "active",
    paidPlan: false,
    freeform: 5,
    startDate: cuurentdate.toLocaleTimeString([], {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }),
    endDate: date.toLocaleTimeString([], {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
    totalForm: 50,
    // totalForm: 5,
    planType: "YAMMY",
    isPayment: false,
    formField: [],
  }).then(() => {});
};
const emailEverdayData = async () => {
  const docRef = doc(db, "formData", "everyDayEmailData");
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await updateDoc(docRef, {
        totalsignupPerDay: docSnap.data().totalsignupPerDay + 1,
      });
    } else {
      setDoc(docRef, { totalsignupPerDay: 1, newUserTotalform: 0 });
    }
  } catch (e) {
    console.log(e);
  }
};
const NewuserAddData = async (uid) => {
  const docRef = doc(db, "newUser", "newUserData");
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await updateDoc(docRef, {
        uid: [...docSnap.data().uid, uid],
      });
    } else {
      setDoc(docRef, { uid: [uid] });
    }
  } catch (e) {
    console.log(e);
  }
};

export const sendEmail = () => {
  const user = JSON.parse(window.localStorage.getItem("USER"));
  // const options = {
  //   headers: { Authorization: `Bearer ${user?.stsTokenManager?.accessToken}` },
  // };

  // axios
  //   .post(
  //     "https://europe-west3-process-talks-forms.cloudfunctions.net/sendEmailV2",
  //     options
  //   )
  //   .then((response) => {
  //     console.log("send mail response", response.data);
  //   })
  //   .catch((error) => {
  //     console.log("send email error", error);
  //   });
  var myHeaders = new Headers();
  myHeaders.append("authToken", `Bearer ${user?.stsTokenManager?.accessToken}`);
  var urlencoded = new URLSearchParams();
  urlencoded.append("to", "signups@peasyforms.com");
  urlencoded.append(
    "htmlBody",
    `<h3>New user is registered in web</h3> </br>
    ${user.email}`
  );

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: urlencoded,
  };

  fetch(
    "https://europe-west3-process-talks-forms.cloudfunctions.net/sendMailV1",
    requestOptions
  )
    .then((response) => {
      console.log("send mail response");
    })
    .catch((error) => {
      console.log("send email error", error);
    });
};
const yummyPlaneActive = async (email, uid) => {
  const q = query(collection(db, "users"), where("email", "==", email));

  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    sendEmail();
    NewuserAddData(uid);
    emailEverdayData();
    console.log("new email");
    yummyplane();
  }
};
export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then(async (res) => {
      localStorage.setItem("USER", JSON.stringify(res.user));
      const details = getAdditionalUserInfo(res);
      if (details.isNewUser) {
        await yummyPlaneActive(res.user.email, res.user.uid);
        const citiesRef = collection(db, "users");
        await setDoc(doc(citiesRef, res.user.uid), {
          name: res.user.displayName,
          email: res.user.email,
        });
      }

      // const citiesRefq = doc(db, "emaila");
      // add(citiesRefq, {
      //   to: "darshanvaghasiya2001@gmail.com",
      //   message: {
      //     subject: "Hello from Firebase!",
      //     html: "This is an <code>HTML</code> email body.",
      //   },
      // });
      window.location = "/create-form";
    })
    .catch((error) => {
      console.log(error);
    });
};
export const signOutGoogle = () => {
  signOut(auth)
    .then(() => {
      // localStorage.clear();
      localStorage.removeItem("USER");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("TypeForm_accessToken");
      localStorage.removeItem("API_Data");

      window.location = "/";
    })
    .catch((error) => {
      console.log(error);
    });
};
