import React from "react";

import { Container } from "react-bootstrap";

export function PrivacyPolicy() {
  return (
    <>
      <Container>
        <div style={{ textAlign: "left", color: "#9e9e9e" }}>
          <h1
            style={{ textAlign: "center", color: "black", paddingTop: "4rem" }}
          >
            PRIVACY POLICY
          </h1>
          <p>Scope</p>
          <p style={{ textAlign: "justify" }}>
            This Privacy Policy describes how Process Talks SL (“Process Talks”)
            process your information and personal data. This Privacy Policy
            applies to www.peasyforms.com’s website (the “Site”) as well as the
            services offered by Process Talks (the “Service” or the “Services”).
          </p>
          <p style={{ textAlign: "justify" }}>
            Our Services are addressed to legal age professionals. If you are a
            minor, you may not use our Services or provide us with your personal
            information through the Site or otherwise.
          </p>
          <p style={{ color: "#757575", fontWeight: "700" }}>Data Controller</p>
          <p>The data controller is PROCESS TALKS SL</p>
          <p>
            Address: C/ Del Torn, 17174 Sant Feliu de Pallerols, Girona, Spain
          </p>
          <p>
            Email:<span style={{ color: "black" }}>hello@processtalks.com</span>{" "}
          </p>
          <p style={{ color: "#757575", fontWeight: "700" }}>
            Personal Information
          </p>
          <p>Process Talks treats information as described in this section.</p>
          <p>Categories of Information:</p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              Information provided by you:
            </span>{" "}
            When you register to create an account on the Service, download
            content from the Site, complete a form or survey, or respond to
            communications, you provide us with information such as your name,
            email address, address, company, telephone number, or other
            information. You may also provide comments, communications, ratings,
            or other information when you use the Services.
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              {" "}
              When you create an account:
            </span>{" "}
            When you create an account, you provide us with your registration
            and profile information (name, email address, company and similar
            information). We automatically track certain information about your
            use of the Service. We collect information about you when you log in
            and when you use the Services, the features you use, clicks, and how
            you interact with other users.
          </p>
          <p p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              {" "}
              Content provided by you:
            </span>
            The Services allow you to create electronic forms with third– party
            applications. When you use the Services, information or content is
            transferred from one application to another. When you integrate our
            Services with a third–party application, the information we receive
            depends on the settings, permissions, and Privacy Policy of the
            third–party application.
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              Information provided through Support Channels:
            </span>{" "}
            You may provide information or content through the customer support
            or chat functionality that is available on the Site or in the
            Service interface. When you interact with our support team, in
            addition to your account information, we may ask you to provide
            additional information about your problem, which may include
            information about your technology, software, internet connection, or
            screenshots.
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              {" "}
              Marketing Information:
            </span>{" "}
            We receive information about you directly from you when you interact
            with the Site and automatically when you interact with the Site or
            open or click on emails we send you (see Information You Provide to
            Us and Cookies and Other Tracking Technology). We receive
            information from third–party sources such as lead generation
            providers, content sponsors, event sponsors, or social media
            platforms. The information we collect from third–party sources may
            include demographic or marketing information, contact information
            (name, email, company, address, job title), information about your
            profile on those platforms, or information about purchase intent. We
            may combine this information with the information we collect.
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              {" "}
              Payment Information:{" "}
            </span>
            When you create a payment account within the Services or make a
            transaction with us, we will use a third–party payment processor to
            process the payment. The transaction information that we retain are
            those details that allow us to verify that you paid for the
            Services, including price, currency, payment date, payment method,
            name, and account holder name. We can combine that information with
            your account information.
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              Technical, Device and Connection Information:
            </span>{" "}
            When you use the Services or interact with our Site, we
            automatically collect information about the device you are using to
            access the Site or Services. When you interact with our social media
            sites, the social media platform automatically collects information
            and provides it to us. This information includes details that may
            identify as well as other information like connection type,
            settings, operating system, browser type, IP address, URLs, device
            identifiers, and blocking data.
          </p>

          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              {" "}
              Cookies and Other Tracking Technology:
            </span>{" "}
            We use cookies, web beacons, pixels, or other tracking technology on
            the Site and Services to provide functionality, personalize your
            experience, and recognize you across all services or devices. You
            can learn more about our cookies by checking our Cookies Policy.
          </p>
          <p style={{ color: "#757575", fontWeight: "700" }}>
            How and why we use personal data
          </p>

          <p style={{ textAlign: "justify" }}>
            We use the personal information we collect for the following
            purposes:
          </p>

          <p>●to provide and support the Services,</p>
          <p>●to improve the Services,</p>
          <p>●to provide, support and improve the Site,</p>

          <p>●to process your requests,</p>

          <p>●to process your payments or transactions,</p>

          <p>
            ●to provide you with information about our products and services,
          </p>

          <p>●to adapt the content or information we provide to you,</p>
          <p style={{ textAlign: "justify" }}>
            ●to protect the integrity or security of our business, the Site,
            Services or third–party applications, or we may also use your
            information to carry out our obligations, enforce our rights, comply
            with any legal obligations (including assisting our customers to
            comply with its legal obligations), where necessary for our
            legitimate interests or to fulfill any other purpose for which you
            provided the information.
          </p>
          <p style={{ textAlign: "justify" }}>
            Process Talks use and transfer of information received from Google
            APIs to any other app will adhere to{" "}
            <a href="https://developers.google.com/terms/api-services-user-data-policy/" target="_black">
              Google API Services User Data Policy
            </a>{" "}
            , including the Limited Use requirements.
          </p>
          <p style={{ color: "#757575", fontWeight: "700" }}>Legal grounds</p>
          <p style={{ textAlign: "justify" }}>
            Your data will be processed on the basis of your expressed consent
            by checking and ticking the corresponding authorization and
            acceptance box of this Privacy Policy. In addition, the data may
            also be processed to enable the performance of the contracted
            Service, to comply with a legal obligation and/or to comply with any
            of the legitimate purposes identified in this Privacy Policy.
          </p>
          <p style={{ color: "#757575", fontWeight: "700" }}>
            How We Share Personal Information
          </p>
          <p style={{ textAlign: "justify" }}>
            We may share information under any of the following circumstances:
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              {" "}
              With your consent:
            </span>{" "}
            We may share your information with companies, organizations and
            other third parties with your consent
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              To perform the Services:
            </span>{" "}
            We may share your information with our team members and with our
            service providers in order to enable the provision of the Services.
            This includes the possibility that our suppliers (such as Google)
            can monitor the usage of their applications.
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              {" "}
              To market our Services:
            </span>{" "}
            We may share the information you provide with third–party service
            providers who help us to commercialize our Services. These service
            providers may organize virtual or live events, as well as develop
            email marketing tools, sales engagement, or lead generation tools.
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              To disseminate our activity:
            </span>{" "}
            Process Talks may inform through its social networks the successful
            provision of the Services, provided that the client previously
            grants his authorization.
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              For Corporate Transactions:
            </span>{" "}
            We may share encrypted or pseudonymized personal data with actual or
            potential acquirers, their representatives and other relevant
            participants in, or during negotiations of, any sale, merger,
            acquisition, restructuring, divestiture or change in control
            involving all or a portion of our business or assets, including in
            case of bankruptcy or similar proceedings. If we undergo a corporate
            sale or restructuring, we may disclose information to the buyer or
            other successor–in–interest.
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#757575", fontWeight: "700" }}>
              For Legal Reasons:
            </span>{" "}
            We may share your information if we believe it is necessary to
            comply with any court order or legal obligation, including to
            respond to any governmental or regulatory request. We may also share
            your information if we believe it is necessary to enforce our rights
            or the rights of our customers or users.
          </p>
          <p style={{ color: "#757575", fontWeight: "700" }}>Data retention</p>
          <p style={{ textAlign: "justify" }}>
            The data will be kept for the time period that is considered
            necessary to achieve the purposes pursued by the processing of the
            data and in any case, during the period in which the processing of
            the data may generate some kind of liability to the data controller.
          </p>
          <p style={{ color: "#757575", fontWeight: "700" }}>
            Rights and contact
          </p>
          <p style={{ textAlign: "justify" }}>
            Data subjects have the right to: access, rectify and delete their
            data; request the portability of the data, oppose the treatment or
            request its limitation, as well as withdraw their consent.
          </p>
          <p style={{ textAlign: "justify" }}>
            To exercise the aforementioned rights, the user can send his request
            in writing (by letter or email) to the official address of the
            controller, including the reference “Data protection”.
          </p>
          <p style={{ textAlign: "justify" }}>
            To facilitate his identification, the data subject must attach to
            the application a copy of their ID / passport.
          </p>
          <p style={{ textAlign: "justify" }}>
            If you have any questions or comments about this notice or how we
            collect and use your information you may also direct your request in
            writing (by letter or email) to the controller’s address.
          </p>
          <p style={{ color: "#757575", fontWeight: "700" }}>Data security</p>
          <p style={{ textAlign: "justify" }}>
            Process Talks takes reasonable steps to protect your personal data
            from any loss, misuse and unauthorized access, disclosure,
            alteration or destruction, taking into account the risks involved in
            the processing and the nature of the data. However, you should know
            that no application, Internet or email transmission is completely
            secure or error–free and that therefore we cannot guarantee one
            hundred percent the security of your information. You are also
            responsible for ensuring that your account and passwords are
            protected.
          </p>
          <p style={{ color: "#757575", fontWeight: "700" }}>
            Data storage and international data transfers
          </p>
          <p style={{ textAlign: "justify" }}>
            The information collected will be transferred to and processed in
            the EEA and any other country where Process Talks or its affiliates,
            subsidiaries or third–party service providers maintain facilities or
            personnel. These countries may have data protection laws that are
            different from the laws of your country (and, in some cases, offer a
            different level of protection). In the event that your data is
            transferred outside the territory of the EEA to a country that does
            not have an adequacy decision from the European Commission, Process
            Talks will protect your information by entering into a data export
            contract containing the Standard Clauses approved by the European
            Commission.
          </p>
          <p style={{ color: "#757575", fontWeight: "700" }}>
            Notice of changes
          </p>
          <p style={{ textAlign: "justify" }}>
            The Services, the Site and our business may change. As a result,
            this Privacy Policy may be modified. We have the right to update or
            modify this Privacy Policy at any time. If we make changes to the
            Privacy Policy, we will post the changes on this page and indicate
            at the top of this page the date when these terms were last revised.
            If we make material changes, we will notify you through the user
            interface of the Services, such as a pop–up window on the Site, in
            an email notification, or by other reasonable means. In order to
            continue using the Site or Services, you must accept the
            modifications.
          </p>
          <p style={{ color: "#757575", fontWeight: "700" }}>
            Conditions under we process user’s responsibility data
          </p>
          <p style={{ textAlign: "justify" }}>
            In the performance of the contracted services, it is possible that
            Process Talks must access and consult information and data that are
            processed under user’s responsibility. In this case, Process Talks
            will process data as Data Processor and under the following
            premises:
          </p>
          <p>Process Talks, as well as all its staff, is committed to:</p>
          <p style={{ textAlign: "justify" }}>
            a) Use personal data only for the purpose of enabling the
            performance of the contracted Service.
          </p>
          <p style={{ textAlign: "justify" }}>
            b) Process data in accordance with the user’s instructions.
          </p>
          <p style={{ textAlign: "justify" }}>
            c) Guarantee the confidentiality of the personal data processed
          </p>
          <p style={{ textAlign: "justify" }}>
            d) Ensure that the persons authorized for the processing of personal
            data have undertaken, expressly and in writing, to guarantee
            confidentiality or are under a legal obligation of confidentiality,
            as well as that they have received the necessary training in
            relation to the protection of personal data.
          </p>
          <p style={{ textAlign: "justify" }}>
            e) Take into consideration the privacy and personal data protections
            principles.
          </p>
          <p style={{ textAlign: "justify" }}>
            f) Not to communicate personal data to third parties, unless in case
            of user’s authorization or when it is legally admissible.
          </p>
          <p style={{ textAlign: "justify" }}>
            In the event that Process Talks suborders any of the contracted
            services to a third party, it will ensure that the third party
            complies with this Privacy Policy.
          </p>
          <p style={{ textAlign: "justify" }}>
            Process Talks will assist the user, as far as possible, in
            fulfilling the obligation to respond to requests for the exercise of
            rights by users: right of access, rectification, deletion,
            opposition, limitation of processing, portability of data and right
            not to be subject to automated individualized decisions (including
            profiling).
          </p>
          <p style={{ textAlign: "justify" }}>
            If data subjects exercise their rights before Process Talks, this
            circumstance will be informed to the user. The communication must be
            made immediately and in no case beyond the third working day
            following the receipt of the request, together, when appropriate,
            with the information that may be relevant.
          </p>
          <p style={{ textAlign: "justify" }}>
            Process Talks will notify the user without undue delay and, in no
            case later than 48 hours after becoming aware, of any breach of
            security of the personal data at its expense. The notification shall
            be accompanied by the necessary and relevant documentation to be
            able to register and communicate the breach to the competent
            supervisory authority.
          </p>
          <p style={{ textAlign: "justify" }}>
            If available, at least the following information shall be provided:
          </p>
          <p style={{ textAlign: "justify" }}>
            a) Description of the nature of the security breach, including,
            where possible, the categories and approximate number of data
            subjects affected, and the categories and approximate number of
            personal data records affected.
          </p>
          <p style={{ textAlign: "justify" }}>
            b) Name and contact details, if any, of the data protection officer
            or other contact point from which further information can be
            obtained.
          </p>
          <p style={{ textAlign: "justify" }}>
            c) Description of the possible consequences of the security breach.
          </p>
          <p style={{ textAlign: "justify" }}>
            d) Description of the measures taken or proposed to remedy the
            personal data breach, including, where appropriate, the measures
            undertaken to mitigate the possible negative effects.
          </p>
          <p style={{ textAlign: "justify" }}>
            If it is not possible to provide the aforementioned information it
            shall be provided gradually without undue delay..
          </p>
          <p style={{ textAlign: "justify" }}>
            It will be up to the user, when relevant in accordance with the
            content of precepts 33 and 34 RGPD, to notify security breaches to
            the Control Authority or data subjects.
          </p>
          <p>Process Talks will apply the following security measures:</p>
          <p style={{ textAlign: "justify" }}>
            a) Guarantee the confidentiality, integrity, availability and
            permanent resilience of the treatment systems and services. Apply
            the necessary technical and organizational means to guarantee the
            security and confidentiality of all personal information that is
            delivered. Monitor in any case the correct logical and physical
            protection of personal data in order to avoid its alteration, loss,
            processing or unauthorized access. In this sense, the protection
            extends to any medium, both automated and non–automated, and
            therefore Process Talks undertakes not to include personal data in
            records that do not meet the conditions that the applicable
            regulations may determine at any time.
          </p>
          <p style={{ textAlign: "justify" }}>
            b) Check and Assess the availability and access to personal data
            quickly, in case of physical or technical incident.
          </p>
          <p style={{ textAlign: "justify" }}>
            c) Verify, evaluate and assess, on a regular basis, the
            effectiveness of the technical and organizational measures
            implemented to guarantee the security of the data processing.
          </p>
          <p style={{ textAlign: "justify" }}>
            d) Pseudonymize and encrypt personal data, if applicable.
          </p>
          <p style={{ textAlign: "justify" }}>
            At the end of the service provided, Process Talks commits to:
          </p>
          <p style={{ textAlign: "justify" }}>
            Destroy all personal data once the service has been completed.
            However, Process Talks may keep, under its sole responsibility, a
            copy of the data –duly blocked– as long as any liability related to
            the execution of the service may arise.
          </p>
          <p style={{ textAlign: "justify" }}>
            The user undertakes to provide Process Talks with the information
            necessary to enable the provision of the contracted Service and to
            ensure, prior to and throughout the processing of personal data,
            compliance with current regulations.
          </p>
        </div>
      </Container>
    </>
  );
}
